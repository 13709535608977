.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    overflow: auto;
}

.settings {
    flex: 1;
    padding: 32px;
    align-self: stretch;
    box-sizing: border-box;
    .fields {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}