@import '../colors.scss';

@media screen and (min-width: 1501px) {
    .sidebar_sticky {
        position: sticky;
        top: 175px;
    }    
}

.statistics {
    padding: 24px;
    padding-left: 9px;
    h2 {
        margin: 0 0 25px 15px;
        color: $title;
        font-size: 16px;
        font-weight: 600;
        grid-row: 1;
        grid-column: 1;
    }
}

.treeStructure {
    min-width: 900px;
    height: 100%;
    > img {
        height: 80px;
        width: 80px;
        margin: 16px;
    }
}

.copied {
    display: flex;
    align-items: center;
    margin-left: 16px;
    svg {
        color: $steel;
        height: 16px;
        width: 16px;
        margin-right: 4px;
    }
    p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        color: $steel;
    }
}

.invoicingAddress {
    :global {
        .datalist-container {
            margin-bottom: 4px;
        }
    }
}

.roleTags {
    display: flex;
    align-items: center;
    :global {
        .status-tag {
            margin-right: 6px;
            padding: 6px 8px;
            font-size: 9px;
            min-width: 60px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.addressContainer {
    margin-top: 16px;
}

.stageIndicators {
    display: flex;
    align-items: center;
    margin-top: 11px;
    margin-bottom: 14px;
    height: 18px;
    width: 100%;
    max-width: 500px;
    > span {
        height: 100%;
        width: 100%;
    }
    .stageIndicator {
        flex: 1;
        height: 100%;
        box-sizing: border-box;
        background-color: #eee;
        border-radius: 3px;
        margin-right: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 10px;
        cursor: pointer;
        overflow: hidden;
        padding: 0 4px;
        p {
            text-overflow: ellipsis;
            text-align: center;
            white-space: nowrap;
            font-size: 10px !important;
            margin: 0;
            padding: 0;
            flex-shrink: unset !important;
        }
        &:last-of-type {
            margin-right: 0;
        }
        &.done {
            background-color: #2d9ff7;
        }
        &.lost {
            background-color: #f7548f;
        }
        &.internal {
            background-color: #6e41c0;
        }
        &.hold {
            background-color: #feae0e;
        }
        &.won {
            background-color: #4fe2c1;
        }
        &:hover {
            opacity: 0.6;
        }
    }
}

.teamListTeamIcon {
    fill: #4E616F;
    width: 24px;
    height: 24px;
}
