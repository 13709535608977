@import '../colors.scss';

.withTabs {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    z-index: 1;
    .withTabs {
        z-index: 0;
    }
    .titleSection {
        height: 50px !important;
        padding-left: 28px !important;
        padding-right: 28px !important;
        margin-top: 2px !important;
        z-index: 1;
        h1 {
            line-height: unset !important;
        }
    }
    .noTabs {
        margin-bottom: -8px !important;
    }
}

.upgradeView {
    flex: 1;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    box-sizing: border-box;
    img {
        height: 360px;
        max-height: 50vh;
    }
    h1,
    p {
        margin: 0;
    }
    h1 {
        font-size: 20px;
        text-transform: uppercase;
        margin-bottom: 4px;
    }
    p {
        color: $steel !important;
        font-weight: bold !important;
    }
}

@media screen and (max-height: 700px) {
    .withHeader {
        margin-top: 12px;
        .titleSection {
            display: none !important;
        }
    }
}
