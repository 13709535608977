.top {
    padding: 20px;

    .filterContainer {
        > div {
            width: 100%;
            box-sizing: border-box;
        }
        :global {
            .searchTextInputWrapper {
                padding-left: 0;
                input {
                    width: 100%;
                }
            }
        }
        .textFilter {
            display: flex;
            align-items: center;
            justify-content: space-between;

            img {
                margin-top: 0;
                margin-left: 0;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
}

.sections {
    width: 600px;
    padding: 0px 20px;
    overflow-y: auto;
    overflow-x: hidden;
}

.header {
    font-size: 16px;
    margin: 0;
    margin-bottom: 8px;
    padding: 0px 20px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}