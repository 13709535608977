.upgradeVersionPlaceholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    > div {
        width: 500px;
        max-width: 100%;
        height: fit-content;
        flex: unset;
        max-height: 100%;
    }
}
