.row {
    p {
        margin: 0;
        text-align: left;
        text-indent: 16px;
    }
    .negative {
        p {
            color: #f7548f;
        }
    }
}