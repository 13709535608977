@import '../../colors.scss';

.root {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background: #2D9FF70D;
    }
}

.info {
    overflow: hidden;
    margin-right: 16px;

    .name {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .description {
        font-size: 11px;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-all;
    }
}

.starArea {
    margin-top: 6px;

    svg:hover {
        opacity: 0.5;
        cursor: pointer;
    }
}

.left {
    overflow: hidden;
}

.right {
    button {
        min-width: 0;
    }
}

.left, .right {
    display: flex;
    gap: 10px;
}

.menuButton {
    padding: 5px;
}

.description {
    font-size: 11px;
    color: #6F6F6F;
}

.defaultIcon, .sharedIcon {
    height: 24px;
    width: 24px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-right: 5px;

    > svg {
        height: 12px;
    }
}

.defaultIcon {
    background: #003A7830;
}

.sharedIcon {
    background: #6F42C130;

    > svg {
        color: #7646C3;
    }
}

.star {
    color: #FFC121;
}

.emptyStar {
    color: #CCCCCC;
}

.menuItem {
    svg {
        width: 20px;
        height: 20px;
        margin-right: 12px;
    }
    
    &.delete {
        color: $faded-red !important;
        svg {
            color: $faded-red;
       }
    }
}