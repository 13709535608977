@import '../colors.scss';

.ssoDialog {
    padding: 32px;
    >h3 {
        margin-top: -5px;
    }
}
.errorAlert {
    width: 100%;
    color: #f97fab;
    background-color: rgba(249, 127, 171, 0.08);
    border: solid 1px #f97fab;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;

    >p {
        padding-left: 15px;
        padding-right: 15px;
    }
}
.ssoButton {
    background-color: $heerosBlue;
    width: 100%;
    padding: 13px 0;
    display: inline-flex !important;
    >img {
        margin-right: 10px;
    }
}
.divider {
    width: 100%;
    height: 2px;
    margin: 18px 0;
    background-color: $pale-grey-four;
}
.ssoField {
    width: 100%;
    margin-bottom: 16px;
}
.ssoButton2 {
    background-color: $heerosBlue;
    width: 100%;
}
.closeDialogButton {
    position: absolute;
    right: 8px;
    top: 8px;
    color: $steel;
}