@import '../colors.scss';

.logoUpload {
    input {
        display: none;
    }
    > p {
        margin: 0;
        margin-top: 16px;
        color: $steel;
        font-weight: bold;
        font-size: 12px;
    }
    .container {
        background-color: #f4f4f4;
        padding: 16px 20px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        .selectedFile {
            > button {
                color: $faded-red !important;
                margin-bottom: 4px;
            }
            p {
                margin: 0;
                &:first-of-type {
                    font-weight: bold;
                    word-break: break-all;
                }
            }
        }
        button {
            background: none;
            border: none;
            padding: 0;
            font-weight: bold;
            color: $dark-sky-blue;
            cursor: pointer;
            font-family: 'Open Sans', sans-serif;
        }
        svg {
            color: $steel;
            height: 40px;
            width: 40px;
            margin-right: 12px;
        }
    }
}

.invoicePreview {
    background-color: white;
    width: 600px;
    max-width: 70%;
    max-height: 80%;
    min-height: 600px;
    height: 1000px;
    border-radius: 2px;
    -webkit-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 5px 20px -10px rgba(0, 0, 0, 0.4);
    transition: transform 600ms ease-in-out;
    border-left: 3px solid $dark-sky-blue;
    overflow: hidden;
    &.addressFocus {
        transform: scale(1.4) translateY(120px) translateX(-80px);
    }
    &.bankAccountFocus {
        transform: scale(1.4) translateY(-120px) translateX(-80px);
    }
    &.logoFocus {
        transform: scale(1.4) translateY(120px) translateX(80px);
    }
    &.clientFocus {
        transform: scale(1.4) translateY(80px) translateX(80px);
    }
    &.rowFocus {
        transform: scale(1.3) translateY(-40px) translateX(0px);
    }
    position: relative;
    .topSection {
        padding: 32px;
        display: flex;
        flex-direction: column;
        background-color: #f9f9f9;
        .ownData {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            .logo {
                position: relative;
                display: flex;
                align-items: center;
                height: calc(177px * 0.6);
                width: calc(217px * 0.6);
                border-radius: 5px;
                background-color: #eee;
                overflow: hidden;
                flex-shrink: 0;
                margin-right: 16px;
                .uploadOverlay {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background-color: rgba(255, 255, 255, 0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > img {
                        height: 40px !important;
                        width: 40px !important;
                    }
                }
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
        }
        .clientData {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 20px;
            .address {
                align-items: flex-start;
                p {
                    text-align: left;
                    &:first-of-type {
                        text-transform: uppercase;
                    }
                }
                > span {
                    &:nth-child(2) {
                        width: 80px;
                    }
                    &:nth-child(3) {
                        width: 60px;
                    }
                    &:nth-child(4) {
                        width: 60px;
                    }
                    &:nth-child(5) {
                        width: 80px;
                    }
                    &:nth-child(6) {
                        width: 50px;
                    }
                    &:nth-child(7) {
                        width: 40px;
                    }
                }
            }
            h2 {
                font-size: 18px;
                margin-bottom: 4px;
            }
        }
        .skeletonBlock {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            span {
                height: 12px;
                margin: 4px 0;
                width: 120px;
                max-width: 100%;
                background-color: #eee;
                border-radius: 2px;
                &:nth-child(2) {
                    width: 80px;
                }
                &:nth-child(3) {
                    width: 60px;
                }
            }
        }
    }
    .address {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        overflow: hidden;
        p {
            font-size: 10px;
            margin: 0;
            margin-bottom: 3px;
            text-align: right;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
        span {
            height: 8px;
            margin: 4px 0;
            width: 60px;
            max-width: 100%;
            background-color: #eee;
            border-radius: 2px;
            &:nth-child(2) {
                width: 80px;
            }
            &:nth-child(3) {
                width: 60px;
            }
            &:nth-child(4) {
                width: 60px;
            }
        }
    }
    .footerSection {
        background-color: #f9f9f9;
        display: flex;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 24px;
        .address {
            align-items: flex-start;
            p {
                text-align: left;
            }
        }
        .bankAccount {
            align-items: flex-end;
            flex-shrink: 0;
            margin-left: 16px;
            p {
                white-space: nowrap;
                text-align: right;
            }
        }
        .skeletonBlock {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 16px;
            span {
                height: 12px;
                margin: 4px 0;
                width: 120px;
                max-width: 100%;
                background-color: #eee;
                border-radius: 2px;
                &:nth-child(2) {
                    width: 80px;
                }
                &:nth-child(3) {
                    width: 60px;
                }
            }
        }
    }
    .rowSection {
        display: flex;
        flex-direction: column;
        padding: 24px 32px;
        .header {
            display: flex;
            align-items: center;
            border-bottom: 1px solid #aaa;
            padding-bottom: 8px;
            opacity: 0.7;
            > div {
                width: 80px;
                font-size: 11px;
                font-weight: bold;
                text-align: right;
                padding: 0 4px;
                box-sizing: border-box;
                &:first-of-type {
                    flex: 1;
                    text-align: left;
                }
            }
        }
        .row {
            display: flex;
            align-items: center;
            margin-top: 6px;
            > div {
                width: 80px;
                font-size: 11px;
                padding: 0 4px;
                box-sizing: border-box;
                font-weight: bold;
                text-align: right;
                overflow: hidden;
                p {
                    margin: 0;
                    padding: 0;
                    width: 100%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &:first-of-type {
                    flex: 1;
                    text-align: left;
                    display: flex;
                    flex-direction: column;
                    p {
                        word-break: normal;
                    }
                    .description {
                        font-style: italic;
                        color: #aaa;
                        font-weight: normal;
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
.goBack {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: #6b7897;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    align-self: flex-start;
    transition: background-color 300ms ease-in-out;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px 8px;
    margin-left: -14px;
    margin-bottom: -2px;
    &:hover {
        background-color: #eee;
    }
}
.progress {
    margin-top: 32px;
}
.rowEditor {
    display: flex;
    flex-direction: column;
    .rows {
        display: flex;
        flex-direction: column;
        // max-height: 50vh;
        // overflow: auto;
        .rowFields {
            display: flex;
            flex-direction: column;
            position: relative;
            .deleteButton {
                position: absolute;
                height: 20px;
                width: 20px;
                right: -30px;
                top: 35px;
                border: none;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                background-color: transparent;
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
            hr {
                width: 100%;
                height: 1px;
                background-color: #eee;
                border: none;
                margin: 14px 0 12px 0;
            }
            &:first-of-type {
                .deleteButton {
                    top: 8px;
                }
                hr {
                    display: none;
                }
            }
        }
    }
}
.addRowButton {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: $dark-sky-blue;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
    padding: 4px 8px;
    transition: background-color 300ms ease-in-out;
    width: fit-content;
    margin-top: 6px;
    margin-bottom: 1px;
    font-size: 12px;
    &:hover {
        background-color: rgba(45, 159, 247, 0.06);
    }
    p {
        margin: 0;
    }
    svg {
        color: $dark-sky-blue;
        height: 20px;
        width: 20px;
        margin-right: 6px;
    }
}
