.root {
    min-width: 400px;
    padding: 16px;
    padding-top: 6px;

    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.groups {
    flex: 1;
    overflow: auto;
}

.group {
    h2 {
        margin: 0;
        font-size: 14px;
        padding: 8px 0;
        user-select: none;
    }
}

.option_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.actions {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
}