@import '../colors.scss';

.customizeAppearanceView {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    section {
        width: 100%;
        box-sizing: border-box;
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        h2 {
            font-size: 14px;
            margin: 0;
            padding: 8px 12px;
            color: $heerosBlue;
        }
        > button {
            margin: 12px;
            width: calc(100% - 24px) !important;
            height: 60px !important;
            line-height: 1;
        }
        button {
            position: relative;
            font-family: 'Open Sans', sans-serif;
            color: $heerosBlue;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 10px;
            background: white;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
            cursor: pointer;
            &:hover {
                background-color: rgba(45, 159, 247, 0.06);
            }
            .arrow {
                color: $heerosBlue;
                margin-left: 8px;
                margin-right: -8px;
            }
            span {
                max-width: 20px;
                max-height: 45px;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                transition: max-width 300ms ease-in-out, max-height 300ms ease-in-out;
                transform: rotateZ(-45deg);
            }
        }
        .themes {
            max-width: 360px;
            display: flex;
            flex-wrap: wrap;
            box-sizing: border-box;
            .theme {
                width: 50%;
                height: 130px;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 12px;
                &.selected {
                    button {
                        border: 2px solid $dark-sky-blue;
                        span {
                            max-width: 35px;
                            max-height: 35px;
                        }
                    }
                }
            }
        }
    }
}
