@import "../../../common.scss";

#hour-grid {
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    overflow: hidden;

    &.grid-column-resize {
        user-select: none;

        :not(.grid-header-cell) {
            pointer-events: none;
        }

        cursor: ew-resize;
    }

    .ReactVirtualized__Table__headerRow {
        align-items: unset;
    }

    .grid-level-0, .grid-cell-level-0 {
        .grid-table-cell-first {
            padding-left: 5px;
        }
    }

    .grid-level-1, .grid-cell-level-1 {
        background-color: $light-gray;

        .grid-table-cell-first {
            padding-left: 25px;
        }
    }

    .grid-level-2 {
        background-color: $light-gray2;

        .grid-table-cell-first {
            padding-left: 25px;
        }
    }

    &.hour-grid-project {
        .grid-level-1, .grid-cel-level-1 {
            .grid-table-cell-first {
                padding-left: 15px;
            }
        }

        .grid-level-2 {
            .grid-table-cell-first {
                padding-left: 35px;
            }
        }
    }

    .hour-grid-container {
        display: flex;
        position: relative;
        flex: 1;
        overflow: hidden;

        .grid-table-cell {
            display: flex;
            align-items: center;
            font-size: 13px;

            > div.grid-table-subcell {
                flex: 1;
            }

            h3 {
                font-size: 13px;
            }

            .expandOrClose {
                width: 25px;
                min-width: 25px;

                .expand-icon {
                    width: 6x;
                    height: 6px;
                    color: gray;

                    &.up {
                        transform: rotate(180deg);
                    }
                }
            }

            .user-cell-labels {
                padding-left: 5px;
            }
        }

        .hidden-scroll {
            overflow: hidden !important;
        }

        .no-outline {
            outline: none !important;
        }

        .table-header-cell {
            box-sizing: border-box;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;

            &:not(:last-of-type) {
                border-right: $cell-border;
            }

            &:last-of-type {
                margin-right: 0;
            }

            font-size: 12px;
            color: $dark;
            text-transform: none !important;
            padding-left: 7px;

            &.table-header-resize {
                span {
                    flex: 1;
                    overflow: hidden;
                }

                .dragmarker {
                    cursor: ew-resize;
                    height: 56px;
                    width: 10px;
                }

                .headerAndMarker {
                    display: flex;
                    height: 56px;
                    align-items: center;
                }
            }
        }

        .grid-row {
            &.expandable-list-row, &.dialog-editable {
                cursor: pointer;
            }

            p {
                font-size: 12px;
            }

            h3 {
                font-size: 13px;
            }
        }

        .user-cell {
            display: flex;
            align-items: center;

            .expand-icon-container {
                margin-left: -15px;
                width: 50px;
                min-width: 50px; // Fixes with long names
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                .expand-icon {
                    width: 6x;
                    height: 6px;
                    color: gray;

                    &.up {
                        transform: rotate(180deg);
                    }
                }
            }

            .user-cell-labels {
                display: flex;
                flex-direction: column;
                overflow: hidden;
                margin-left: 15px;

                h3 {
                    font-size: 13px;
                    text-overflow: ellipsis;
                }

                p {
                    font-size: 10px;
                    text-overflow: ellipsis;
                    color: #9ea0a5;
                }
            }
        }

        .grid-header-cell, .grid-footer-cell, .grid-footer-right-cell {
            box-sizing: border-box;
            border-bottom: 1px solid #eee;
            border-right: 1px solid #eee;
            font-weight: bold;
            display: flex;
            font-size: 10px;
            overflow: hidden;
            white-space: nowrap;
            align-items: center;
            text-overflow: ellipsis;

            &.grid-header-cell, &.grid-footer-right-cell {
                justify-content: center;
            }

            &.grid-footer-cell {
                padding-left: 7px;
            }

            &.grid-footer-cell-user {
                font-weight: 600;
                font-size: 11px;
            }
        }

        .grid-footer-left-columns {
            background: #F7F8FC;
        }

        .grid-footer-right {
            background: #F7F8FC;
        }

        .grid-footer-right-cell, .grid-footer-cell {
            background: #F7F8FC;
            border-right: 0;
            border-bottom: 0;
        }

        .border-bottom {
            border-bottom: $cell-border;
            box-sizing: border-box;
        }

        .no-scrollbar {
            scrollbar-width: none;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }
        }

        .grid {
            flex: 1;
            overflow: hidden;

            .grid-cell {
                border-right: $cell-border;
                border-bottom: $cell-border;
                width: 100%;
                height: 100%;
                font-weight: bold;
                font-size: 12px;
                box-sizing: border-box;

                &.grid-has-subitems {
                    cursor: pointer;
                }

                &.task-editable {
                    cursor: pointer;

                    &:hover {
                        opacity: 0.7;
                        outline: 2px solid #2d9ff7;
                        outline-offset: -3px;
                    }

                    &.selected {
                        outline: 2px solid #2d9ff7;
                        outline-offset: -3px;
        
                        &:hover {
                            opacity: 0.9;
                            outline: 2px solid #2d9ff7;
                            outline-offset: -3px;
                        }
                    }
                }

                &.task-not-editable {
                    background: #f9f9f9;
                }

                .grid-cell-container {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }

        .grid-left {
            border-right: $cell-border;

            .ReactVirtualized__Table__rowColumn {
                margin: 0;
                padding-left: 7px;
                box-sizing: border-box;
            }
        }

        .grid-footer-left {
            > div {
                overflow: hidden !important;
            }

            > div.grid-footer-left-columns {
                border-top: $cell-border;
            }
        }

        .grid-footer-right {
            border-top: $cell-border;

            > div {
                overflow: hidden !important;
            }
        }

        // .grid-footer {
        //     position: relative;
        // }
   
    }

    .controls-container {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        padding: 6px 20px 6px 10px;
        background-color: $light-gray;

        .tab-container {
            display: flex;
            align-items: center;
        }

        .actions-container {
            display: flex;
            align-items: center;

            // color: $main-blue;
            white-space: nowrap;

            .hideColumnSelector {
                cursor: pointer;
                font-size: 12px;
                color: #2d9ff7;
                font-weight: bold;
                display: flex;
                align-items: center;
                margin-left: 5px;
                box-sizing: border-box;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                border-radius: 4px;
                padding: 0 4px;
                height: 44px;

                svg {
                    width: 20px;
                    height: 20px;
                    color: inherit;
                    fill: currentColor;
                    margin-right: 4px;
                }

                &:hover {
                    background-color: #d8d8d8;
                }
            }

            .button-container {
                position: relative;

                .button-icon {
                    height: 20px;
                    width: 20px;
                    font-size: 20px;
                    line-height: 20px;
                    color: $main-blue;
                    font-weight: bold;
                    fill: $main-blue;
                    margin-right: 7px;
                }

                .button-menu-container {
                    position: absolute;

                    // top: 40px;
                    // padding-top: 10px;
                    left: 0;
                    z-index: 999;
                    transition: opacity 0.2s ease-in-out;
                }

                .header-button {
                    display: flex;
                    align-items: center;
                    background-color: transparent;
                    font-weight: bold;
                    min-width: 0;
                    padding: 10px;
                    margin: 0;
                    margin-right: 10px;
                    color: $main-blue;
                    white-space: nowrap;

                    &:hover {
                        background-color: #d8d8d8;
                    }

                    .dropdown-icon {
                        color: $main-blue;
                        padding: 0;
                        width: 20px;
                        margin-left: 2px;
                        margin-right: -5px;
                    }
                }
            }
        }
    }
}

.TimeTrackingBtnContainer {
    float: right;
    cursor: pointer;
    padding-top: 4px;
    padding-right: 2px;

    .WorkhoursIcon {
        width: 20px;
        height: 20px;
    }

    .TimeTrackingBtnTxt {
        font-size: 9px;
        color: #6b7897;
        position: relative;
        top: -6px;
    }

    &:hover {
        opacity: 0.4;
    }
}

.grid-control-button-menu {
    min-width: 200px;
    padding: 8px 0;
    background-color: white;
    -webkit-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;

    .menu-item {
        height: 45px;
        display: flex;
        align-items: center;
        position: relative;
        padding: 0 17px;

        .multi-select-box {
            width: 20px;
            height: 20px;
            border: 1px solid #b2b9c9;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &:hover {
            background-color: rgba(44, 159, 247, 0.08);
            cursor: pointer;
        }

        .menu-item-icon {
            height: 15px;
            width: 15px;
            line-height: 15px;
            font-size: 15px;
            font-weight: bold;
            fill: #b2b9c9;
            color: #b2b9c9;
            margin-left: -2px;
        }

        p {
            white-space: nowrap;
            font-weight: bold;
            font-size: 12px;
            margin-left: 10px;
            color: $dark;
        }
    }
}
