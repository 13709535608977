.loadingOverlay {
    position: absolute;

    // left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    // margin-left: -50px;
    // height: 100%;
    // top: 0;
    width: calc(100% - 32px);
    top: -57px; 
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: 190px;
    z-index: 3;
    background: #fff;
    opacity: 1;
    // Commented out now, since we want no flickering,
    // and for the transition to be as snappy as possible.
    // transition: opacity 0.1s;

    &.hidden {
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.5s;
    }
}
