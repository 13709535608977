@import '../colors.scss';

.dropdownMenuButton {
    > button {
        border: none;
        padding-right: 35px;
        background-color: $light-sky-blue;
        color: $dark-grey-blue;
        svg {
            fill: $dark-grey-blue;
            background: $light-sky-blue;
            margin-right: 10px;
        }
        &:hover {
            background-color: $light-sky-blue;
            border: none;
        }
    }
    :global {
        #menu-list-grow {
            svg {
                margin-right: 10px;
                margin-top: 2px;
                width: 14px;
                height: 14px;
            }
        }
    }
}
