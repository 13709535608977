@import '../colors.scss';

.pageContent {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    box-sizing: border-box;
    flex: 1;
    height: 100%;
    align-self: stretch;
    overflow: hidden;
    &.top {
        justify-content: flex-start;
    }
    &.center {
        justify-content: center;
    }
    .testModeIndicator {
        position: absolute;
        bottom: 16px;
        right: 16px;
        background-color: rgba(111, 66, 193, 0.08);
        padding: 8px 16px;
        border-radius: 5px;
        width: fit-content;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: bold;
        color: #6f42c1;
        font-size: 13px;
        user-select: none;
        svg {
            color: #6f42c1;
            margin-right: 6px;
            margin-left: -2px;
            height: 20px;
            width: 20px;
        }
    }
    > div {
        width: 450px;
        max-width: 100%;
        padding: 40px;
        overflow: auto;
        max-height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;
        > img {
            align-self: center;
            width: 200px;
            height: 200px;
            margin-bottom: 48px;
        }
        .animation {
            align-self: center;
            width: 300px;
            height: 300px;
            margin-top: -40px;
            > div {
                width: 100% !important;
                height: 100% !important;
            }
        }
        h1,
        h4 {
            margin: 0;
            padding: 0;
        }
        h1 {
            font-size: 24px;
        }
        h4 {
            color: #6b7897;
            margin-bottom: 4px;
        }
        .subtitle {
            margin: 0;
            padding: 0;
            color: #6b7897;
            font-weight: bold;
            margin: 4px 0 16px 0;
        }
        .description {
            margin: 0;
            padding: 0;
            color: #6b7897;
            font-weight: bold;
            margin: 16px 0 !important;
        }
        .pageCustomContent {
            margin-top: 8px;
        }
        .pageFields {
            margin-top: 16px;
            display: flex;
            flex-direction: column;
            .optionalFieldButton {
                background: none;
                border: none;
                display: flex;
                align-items: center;
                color: $dark-sky-blue;
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                cursor: pointer;
                border-radius: 5px;
                padding: 4px 8px;
                transition: background-color 300ms ease-in-out;
                width: fit-content;
                margin-top: 3px;
                margin-bottom: 1px;
                font-size: 12px;
                &:hover {
                    background-color: rgba(45, 159, 247, 0.06);
                }
                p {
                    margin: 0;
                }
                svg {
                    color: $dark-sky-blue;
                    height: 20px;
                    width: 20px;
                    margin-right: 6px;
                }
            }
            :global {
                .MuiInputBase-multiline {
                    height: 120px;
                    textarea {
                        height: 100% !important;
                        white-space: pre-line;
                    }
                }
            }
        }
        .pageAction {
            text-transform: uppercase;
            margin-top: 16px;
            height: 44px;
            width: 100%;
        }
    }
    &.light {
        h1,
        h4,
        .subtitle {
            color: white;
        }
    }
    .secondaryPageAction {
        background: none;
        border: none;
        font-family: 'Open Sans', sans-serif;
        font-weight: bold;
        color: #6b7897;
        cursor: pointer;
        border-radius: 5px;
        padding: 4px 8px;
        transition: background-color 300ms ease-in-out;
        display: flex;
        align-items: center;
        margin-top: 8px;
        svg {
            color: $steel;
        }
        &:hover {
            background-color: #eee;
        }
    }
    .skipButton {
        position: absolute;
        right: 16px;
        top: 16px;
    }
    .backButton {
        position: absolute;
        left: 16px;
        top: 16px;
    }
}
.pageImage {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #eee;
    overflow: hidden;
    > img {
        width: 400px;
        max-width: 80%;
    }
}
