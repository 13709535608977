@import '../colors.scss';

.accountingIconContainer {
	cursor: pointer;
    display: flex;

    svg {
        width: 20px;
        height: 20px;
        fill: #B8B8B8;
        path {
            fill: #B8B8B8;
        }

        &.active {
            fill: #2D9FF7;
            path {
                fill: #2D9FF7;
            }
        }
    }
}

.accountingCellTooltip {
    p {
        margin: 0;
        padding: 2px 4px;
        &:first-of-type {
            margin-top: 2px;
        }
    }
    button {
        background: none;
        border: none;
        padding: 2px 4px;
        color: $dark-sky-blue;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        margin-bottom: 2px;
        cursor: pointer;
    }
}