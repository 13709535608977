@import '../colors.scss';

.container {
    display: flex;
}
.accountingSlider {
    flex: 1;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .loading {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
    }

    .rows {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 20px 0px 20px 20px;
        box-sizing: border-box;
        overflow: auto;

        h1 {
            font-weight: 600;
            font-size: 20px;
            line-height: 27px;
            color: #4E5B74;
            margin: 0px;
            margin-top: 20px;
            margin-bottom: 10px;
        }

        h1:first-child {
            margin-top: 0px;
        }

        ul {
            list-style-type:none;
            display: flex;
            padding-left: 0px;
            margin-bottom: 2px;
            margin-top: 2px;

            .emptyRightMargin {
                padding-right: 10px;
            }
        }

        ul:first-child {
            margin-top: 0px;
        }

        ul li {
            display: flex;
            align-items: center;
            list-style-type:none;
            align-self: center;
            &.rowInfo {
                width: 200px;
                margin-right: 15px;
                display: inline-block;
                flex-shrink: 0;
                .rowDescription {
                    width: 100%;
                    font-weight: bold;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;

                }
            }
            .accountingField {
                width: 160px;
                margin-right: 10px;

                div[class*="MuiFormControl"] {
                    margin-top: 4px;
                    margin-bottom: 4px;
                }
            }
            .copyIconContainer {
                margin-right: 20px;
                cursor: pointer;
                svg {
                    fill: #2D9FF7;
                }
            }
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 16px;
    box-sizing: border-box;
    .right {
        display: flex;
        align-items: center;
        .options {
            button {
                border: none;
                background-color: $light-sky-blue;
                color: $dark;
                padding-right: 28px;
                padding-left: 16px;
                &:hover {
                    border: none;
                }
                svg {
                    fill: $dark;
                    background-color: transparent;
                }
            }
            &.add {
                button {
                    background-color: $greenish-cyan;
                    color: white;
                    svg {
                        fill: white;
                    }
                }
            }
            :global {
                #menu-list-grow {
                    li {
                        font-size: 12px;
                        padding-top: 4px;
                        padding-bottom: 4px;
                        font-weight: bold;
                        svg {
                            color: $dark !important;
                            width: 20px;
                            height: 20px;
                            margin-right: 4px;
                            margin-left: -2px;
                        }
                    }
                }
            }
        }
    }
    button {
        margin-left: 8px;
    }
}
