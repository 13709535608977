$height-full: calc(100vh - 200px);

.root {
    // background: #F9F9F9;
    padding: 10px;
    min-height: $height-full;
    display: flex;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
}

.percentbox {
    background: rgba(66, 182, 119, 0.2);
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    color: #42B677;
    padding: 4px 6px;
    border-radius: 3px;
    margin-left: 10px;
    white-space: nowrap;
}

.trackedSubFigures {
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.figure_income {
    font-weight: bold;
    color: #42B677;
}

.figure_expenses {
    font-weight: bold;
    color: #F7548F;
}

.activity_block {
    padding: 50px;
    display: flex;
    max-width: 100%;

    .chart {
        flex: 1 1 25%;
        width: 25%;
        box-sizing: border-box;
    }

    .legend {
        flex: 1;

        .legend_container {

            > div {
                display: flex;
                align-items: center;
                white-space: nowrap;
            }

            .key {
                color: #6b7897;
                margin-right: 10px;
                margin-left: 5px;
            }

            .color {
                height: 2px;
                width: 16px;
                vertical-align: middle;
                border-radius: 5px;
            }
        }

        .add_activity {
            margin-top: 10px;
        }
    }
}