.errorsListSpace {
    height: 8px;
}

.dateRangePicker {
    // Doesn't work without the important.
    line-height: 32px !important;
    margin-bottom: 4px;
    margin-top: 8px;
}

.dateRangePickerPopperCalendarView {
    @media only all and (max-height: 800px) { 
        position: relative;
        top: -100px;
    }

    @media only all and (max-height: 700px) { 
        position: relative;
        top: -150px;
    }
}

.dateSeparator {
    top: 5px !important;
    left: -10px !important;
}

.datePickerInput {
    margin-top: -10px !important;
}

.dateRangePickerInputWrapper {
    height: 32px;
}
