@import '../colors.scss';

.pdfView {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px;
    overflow: auto;
    box-sizing: border-box;
    > div {
        max-width: 100%;
    }
    .page {
        box-shadow: 0 0 10px -8px #2c3547;
        background-color: white;
        margin-bottom: 16px;
        canvas {
            width: 100% !important;
            max-width: 700px !important;
            height: auto !important;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    .loadingPDF {
        height: calc(100vw * 1.285);
        max-height: 900px;
        width: 700px;
        max-width: 100%;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0 0 10px -8px #2c3547;
        img {
            margin-top: -24px;
            margin-bottom: -8px;
        }
        p {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: bold;
            color: $dark;
        }
    }
}

@media screen and (max-width: 600px) {
    .pdfView {
        padding: 32px 16px;
    }
}
