@import '../colors.scss';

@keyframes drag {
    0% {
        top: 0px;
    }
    50% {
        top: 200px;
    }
    100% {
        top: 0px;
    }
}

.topBarActions {
    display: flex;
    align-items: center;
    align-self: center;
    > div {
        opacity: 0.5;
        pointer-events: none;
        svg {
            height: 30px;
            width: 30px;
        }
        p {
            font-size: 12px !important;
        }
        &:global(.workhour-timer) {
            opacity: 1;
            pointer-events: all;
            svg {
                height: 40px;
                width: 40px;
            }
        }
    }
    > span:last-of-type {
        display: none;
    }
}

.dragAnimationContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.content {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 32px;
    box-sizing: border-box;
    position: relative;
    .loader {
        width: 100px;
        height: 100px;
    }
    .bulkCounter {
        color: $faded-red;
    }
    .description {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        h2 {
            margin: 0;
            color: $steel;
            font-size: 12px;
        }
        svg {
            height: 20px;
            width: 20px;
            margin-right: 6px;
        }
    }
    :global(.no-my-day-drag) {
        height: unset !important;
        border: solid 1px #c3cbdb;
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        overflow: hidden !important;
    }
    &.scrollable {
        overflow: auto;
    }
    .animation {
        position: absolute;
        pointer-events: none;
        left: 50%;
        top: 50%;
        transform: translateY(-45%) translateX(-40%);
    }
    .dragAnimation {
        position: absolute;
        pointer-events: none;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        animation: drag 3000ms ease-in-out;
        animation-iteration-count: infinite;
        width: 200px;
        z-index: 999;
        > div {
            width: 100% !important;
            height: 100% !important;
        }
    }
    .timer {
        padding: 24px;
        background: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #c3cbdb;
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
        > div {
            padding: 8px;
            width: 100%;
            svg {
                height: 50px;
                width: 50px;
            }
            p {
                font-size: 14px;
                margin-top: 4px;
            }
        }
    }
    > div {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        > button {
            background: none;
            border: none;
            display: flex;
            align-items: center;
            color: $dark-sky-blue;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            cursor: pointer;
            border-radius: 5px;
            padding: 4px 8px;
            transition: background-color 300ms ease-in-out;
            width: fit-content;
            margin-top: 3px;
            margin-left: -4px;
            margin-bottom: 16px;
            font-size: 12px;
            &:hover {
                background-color: rgba(45, 159, 247, 0.06);
            }
            p {
                margin: 0;
            }
            svg {
                color: $dark-sky-blue;
                height: 20px;
                width: 20px;
                margin-right: 6px;
            }
        }
        .row {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            align-self: center;
            > button {
                background: none;
                border: none;
                display: flex;
                align-items: center;
                color: $steel;
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                cursor: pointer;
                border-radius: 5px;
                padding: 4px;
                transition: background-color 300ms ease-in-out;
                width: fit-content;
                font-size: 12px;
                margin-left: 8px;
                &:hover {
                    background-color: rgba(45, 159, 247, 0.06);
                }
                p {
                    margin: 0;
                }
                svg {
                    color: $steel;
                    height: 20px;
                    width: 20px;
                }
            }
            > div {
                &:first-of-type {
                    flex: 1;
                    margin-right: 16px;
                }
                &:last-of-type {
                    width: 100px;
                }
            }
        }
    }
}
