@import '../../colors.scss';

.accounting-dimensions-tree-container {
    .mainLevel {
        margin-bottom: 2px;
    }
    .mainListWrapper {
        height: unset !important;
        .delete {
            color: $warm_pink;
            svg {
                fill: $warm_pink;
            }
        }
    }
    .listElement {
        .cell &:not(.contextCell) {
            border: 1px solid #c3cbdb !important;

            &:not(.editMode) {
                border: 1px solid white !important;
            }
            &.permanentEditMode {
                border: 1px solid white !important;
            }
            &.error {
                border: 1px solid #f7548f !important;
            }
        }
        .openChildren {
            .inner {
                display: flex;
                justify-content: center;
            }
        }
        .childExpander {
            margin-left: 0px !important;
        }
        .branchIndicator {
            .ver {
                left: 13px !important;
            }
            .hor {
                left: 13px !important;
            }
        }
        .contextCell {
            background: #f5f5f5 !important;
            border: none !important;
            border-right: none !important;
            border-left: none !important;
            border-bottom: 1px solid #fff !important;
            border-top: 1px solid #fff !important;
        }
        .deleteCell {
            border-right: 1px solid #f5f5f5 !important;
        }
    }
}