@import '../../colors.scss';

.trialPlanHandling {
    display: flex;
    flex-direction: column;
    height: 100%;
    .top {
        h1,
        p {
            margin: 0;
            padding: 0;
        }
        h1 {
            font-size: 20px;
        }
        p {
            color: $steel;
            font-weight: bold;
            font-size: 12px;
            margin-top: 4px;
        }
    }
    .content {
        display: flex;
        flex: 1;
        .cardContainer {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 16px;
            .campaign {
                position: absolute;
                top: 0;
                transform: translateY(-50%);
                background-color: $dark-sky-blue;
                border-radius: 15px;
                padding: 6px 16px;
                color: white;
                font-size: 11px;
                font-weight: bold;
            }
        }
        .cards {
            flex: 1;
            margin-top: 32px;
            display: flex;
            justify-content: center;
            .card {
                width: 280px;
                border-radius: 10px;
                background: white;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
                overflow: hidden;
                height: 100%;
                &.current {
                    width: 300px;
                    .cardTop {
                        background-color: #1e7ecd;
                        button {
                            background-color: $dark-sky-blue;
                            color: white;
                        }
                    }
                }
                .cardTop {
                    background-color: #c7c7c7;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 40px 32px;
                    text-align: center !important;
                    h2,
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    h2 {
                        font-weight: 800;
                        font-size: 26px;
                    }
                    .description {
                        font-weight: bold;
                        font-size: 12px;
                        margin-top: 8px;
                    }
                    .userLimit {
                        font-size: 12px;
                        margin-top: 8px;
                    }
                    .regularPrice {
                        font-size: 12px;
                        text-decoration: line-through;
                        margin-top: 24px;
                    }
                    .price {
                        font-size: 12px;
                        font-weight: bold;
                        strong {
                            font-size: 30px;
                        }
                        margin: 4px 0 12px 0;
                    }
                    button {
                        background-color: $warm-pink;
                        color: white;
                        margin-top: 10px;
                    }
                }
                .cardBottom {
                    padding: 24px;
                    display: flex;
                    flex-direction: column;
                    h3 {
                        font-size: 12px;
                        width: 100%;
                    }
                    ul {
                        width: 100%;
                        padding: 0px 24px;
                        margin: 0;
                        margin-bottom: 20px;
                        li {
                            font-size: 12px;
                            margin-top: 6px;
                        }
                    }
                }
            }
        }
    }
}
