@import '../../colors.scss';

#GothiaImportView {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    height: 100%;
   .addFile {
        display: flex;
        & > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            width: 100%;
            height: 150px;
            color: #6b7897;
            border: 2px dashed #979797;
            border-radius: 6px;
            text-align: center;
            background: #f9f9f9;
            margin: 10px;
        }
        input {
            display: none;
        }
        svg {
            width: 53px;
            height: auto;
            margin-bottom: 10px;
        }
        span {
            font-weight: bold;
            cursor: pointer;
        }
    }
    .listContainer {
        display: flex;
        flex: 1 1;
        box-sizing: border-box;
        overflow: auto;
    }
    .buttonContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        box-sizing: border-box;
        padding: 10px 10px 0;

    }
    .importButton {
        margin-bottom: 15px;
    }
}