@import '../colors.scss';
@import '../theme';

.slider {
    >div {
        max-width: 95vw;
    }
}

:global {
    :local(.slider) {
        >.MuiPaper-root {
            background-color: transparent;
            overflow: visible;
        }
    }
}

.container {
    display: flex;
    height: 100vh;
    .leftContainer {
        height: 100%;
        background-color: white;
        max-width: 0;
        overflow: hidden;
        transition: max-width 200ms ease-in-out;
    
        &.visible {
            max-width: fit-content;
            
        }
    }

}



.mainContent {
    display: flex;
    flex-direction: column;
    background-color: white;
    max-width: 100%;

    .topBar {
        height: 40px;

        @include themedModule() {
            background: t('sliderTopBG');
            color: t('sliderTop') !important;
        }

        display: flex;
        align-items: center;
        color: white;
        padding: 8px 16px;
        flex-shrink: 0;
        position: relative;
        z-index: 2;

        &.largePadding {
            padding-left: 30px;
        }

        .right {
            display: flex;
            align-items: center;
        }

        .leftComponentButton {
            position: absolute;
            left: -20px;
            top: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #2c3547;
            border: none;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            svg {
                transition: transform 300ms ease-in-out;
            }

            &.open {
                svg {
                    transform: rotateZ(180deg);
                }
            }

            &:hover {
                opacity: 1;
            }
        }

        h1 {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 500;
            flex: 1;
        }

        button {
            border: none;
            background: none;
            flex-shrink: 0;
            color: white;
            cursor: pointer;

            svg {
                color: white;
            }

            &:hover {
                opacity: 0.6;
            }
        }
    }
}