@import '../../colors.scss';

  
.mainListWrapper {
    .header {
        .column {
            min-width: 60px;
        }    
    }
}



.settingsTitleCell {
    display: flex;
    justify-content: space-between;
    padding-right: 8px;
    padding-left: 16px;
    >div {
        overflow: hidden;        
        white-space: nowrap;
        cursor: auto;        
    }

    .settingText {
        color: $dark-sky-blue;
        cursor: pointer;
    }
}