@import '../colors.scss';

.externalQuote {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    margin-top: -55px;
    background-color: #f9f9f9;
    .topBar {
        width: 100%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $heerosBlue;
        flex-shrink: 0;
        img {
            height: 40%;
        }
    }
    .error {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        width: 100%;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #eee;
            padding: 64px;
            max-width: 100%;
            > img {
                margin-bottom: 32px;
                width: 200px;
                height: 200px;
            }
            h2,
            p {
                margin: 0;
                padding: 0;
                text-align: center;
            }
            p {
                color: $steel;
                font-weight: bold;
                margin-top: 4px;
            }
        }
    }
    .content {
        flex: 1;
        display: flex;
        width: 100%;
        overflow: hidden;
        .main {
            flex: 1;
            overflow: auto;
            background-color: #f9f9f9;
            display: flex;
            flex-direction: column;
            align-items: center;
            > div:nth-child(2) {
                padding-top: 8px;
            }
            .toolbar {
                width: 732px;
                max-width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 16px 16px 12px 16px;
                background-color: #f9f9f9;
                min-height: 40px;
                span {
                    height: 100%;
                }
                .download {
                    display: flex;
                    align-items: center;
                    color: $steel;
                    background: #eee;
                    text-transform: none;
                    p {
                        margin: 0;
                        padding: 0;
                    }
                    svg {
                        margin-right: 8px;
                        color: $steel;
                        height: 24px;
                        width: 24px;
                        margin-left: -4px;
                    }
                }
                .statusIndicator {
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    font-size: 12px;
                    padding: 8px 16px;
                    border-radius: 5px;
                    > svg {
                        height: 20px;
                        width: 20px;
                        margin-right: 8px;
                        color: inherit;
                    }
                    &.approved {
                        background-color: rgba(80, 227, 194, 0.08);
                        color: $greenish-cyan;
                    }
                    &.declined {
                        background-color: rgba(236, 0, 138, 0.08);
                        color: #ec008a;
                    }
                }
                > div {
                    button {
                        margin-left: 12px;
                        min-height: 40px;
                    }
                    display: flex;
                    align-items: center;
                    height: 100%;
                }
                .approve {
                    background: $greenish-cyan;
                }
                .decline {
                    background: $warm-pink;
                }
            }
        }
        .right {
            width: 400px;
            height: 100%;
            border-left: 1px solid #dde3e8;
            background-color: white;
            padding: 32px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            > div {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                &:nth-child(2) {
                    p {
                        font-size: 12px;
                    }
                }
            }
            img {
                height: 240px;
                width: 240px;
                margin-bottom: 32px;
            }
            h2,
            p {
                margin: 0;
                padding: 0;
                width: 100%;
                text-align: left;
            }
            p {
                font-weight: bold;
                color: $steel;
                margin-top: 8px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .externalQuote {
        .content {
            .right {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .externalQuote {
        .content {
            .main {
                .toolbar {
                    .download {
                        padding: 8px;
                        min-width: 0;
                        p {
                            display: none;
                        }
                        svg {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}
