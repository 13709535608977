@import '../colors.scss';

.activePricelist {
    display: flex;
    align-items: center;
    :global {
        .datalist-container {
            flex: 1;
        }
        .priceListContextMenu {
            margin-left: 8px;
            > button {
                min-width: 0;
                padding: 0;
            }
        }
    }
}

.buttons {
    display: flex;
    margin-right: 10px;
    margin-top: 10px;
}

.priceList {
    .row {
        display: flex;
        align-items: flex-start;
        margin-top: 8px;
        &.center {
            align-items: center !important;
            :global {
                .status-tag {
                    min-width: 65px;
                    padding: 6px 8px;
                    font-size: 10px;
                }
            }
        }
        p {
            margin: 0;
            font-size: 12px;
            font-weight: bold;
            &:first-child {
                font-weight: normal;
                width: 120px;
                margin-right: 16px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .section {
        margin-top: 12px;
        .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            :global {
                .status-tag {
                    min-width: 65px;
                    padding: 6px 8px;
                    font-size: 10px;
                }
            }
        }
        h3 {
            font-size: 12px;
            margin: 0;
            padding: 0;
        }
    }
}

.actions {
    display: flex;
    align-items: center;
    button {
        margin-right: 16px;
    }
}

.switch {
    display: flex;
    align-items: center;
    margin-bottom: -8px;
    margin-top: -2px;
    margin-right: -8px;
    p {
        flex: 1;
        font-size: 12px;
        margin: 0;
    }
}

.addRow {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px;
    margin-bottom: 12px;
    margin-top: 8px;
    .button {
        margin-left: 16px;
    }
    > div {
        flex: 1;
        margin-top: 0;
        margin-bottom: 0;
        :global {
            .textfield {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &.drop {
            flex: 8;

            > div {
                width: 100%;
            }
        }
    }
}

.button {
    background: none;
    border: none;
    color: $dark-sky-blue;
    font-weight: bold;
    font-family: 'Open Sans', sans-serif;
    display: flex;
    align-items: center;
    line-height: 1;
    > svg {
        color: $steel;
        margin-left: 4px;
        // height: 12px;
        // width: 12px;
    }
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 12px;
    &.cancel {
        color: $steel;
    }
    &:hover {
        opacity: 0.6;
    }
}

.add {
    margin-bottom: 14px;
    margin-top: 8px;
}

.activePricelist {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #eee;
    padding-bottom: 16px;
    margin-bottom: 16px;
    > div {
        display: flex;
        align-items: flex-start;
        width: 100%;
        .button {
            margin-right: 16px;
        }
        &:first-child {
            height: 30px;
            justify-content: space-between;
            > div:first-child {
                display: flex;
                align-items: center;
                :global {
                    .status-tag {
                        min-width: 65px;
                        padding: 6px 8px;
                        font-size: 10px;
                    }
                }
            }
            p {
                margin: 0;
                font-size: 14px;
                font-weight: bold;
                margin-right: 16px;
            }
        }
    }
}

.sliderContent {
    width: 500px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .sections {
        flex: 1;
        overflow: auto;
        padding: 16px;
        > .buttons {
            margin-top: 0;
            margin-bottom: 16px;
        }
    }
    .section {
        margin-top: 12px;
        border-bottom: 1px solid #eee;
        padding-bottom: 12px;
        > p {
            font-size: 12px;
            color: $steel;
            margin-bottom: 16px;
        }
        &:first-child {
            margin-top: 0;
        }
        h3 {
            font-size: 15px;
            margin: 0;
            padding: 0;
            margin-bottom: 0;
        }
        .row {
            width: 100%;
            margin-bottom: 8px;
            display: flex;
            align-items: center;
            :global {
                .textfield {
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
            > button {
                svg {
                    height: 20px;
                    width: 20px;
                }
            }
            > div {
                flex: 1;
                margin-top: 0;
                margin-bottom: 0;
                margin-right: 12px;
            }
        }
    }
}
