@import '../../colors.scss';

.section {
    margin-bottom: 28px;
    > p {
        margin: 0;
    }
    > h3 {
        margin-bottom: 8px;
    }
    > canvas {
        margin-top: 6px;
    }
    > table {
        margin-top: 8px;
    }
}
.loginField {
    width: 350px;
}
.loginButton {
    margin-top: 8px;
}
.loginView {
    padding-top: 500px;
    >div {
        border-radius: 10px;
    }
}
.linkedAccounts {
    margin-top: -10px;
    margin-left: -10px;
    margin-bottom:22px;
    li {
        font-weight:bold;
    }
}
.errorText {
    color: $scarlet;
}
.havingIssues {
    display: inline-block;
    margin-left: 15px;
    >a {
        font-weight: bold;
    }
}