@import '../colors.scss';

#onboardingSlider {
    width: 600px;
    max-width: 90vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    overflow: hidden;
    h1,
    p,
    h2 {
        margin: 0;
        padding: 0;
    }
    .content {
        flex: 1;
        overflow: auto;
        .toolbar {
            width: 100%;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            background-color: $pale-grey-three;
            padding: 4px 4px;
            -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
            -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
            box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
            label {
                margin-left: 0;
                margin-right: 4px;
                > span {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }
            }
        }
        > section {
            padding: 0;
            box-shadow: none;
            margin-top: -24px;
            > div {
                padding: 24px;
                background-color: transparent;
                box-shadow: none;
            }
        }
    }
}
