.container {
    display: flex;
    align-items: center;
    font-size: 12px;
    .title {
        font-weight: bold;
    }
    .formControl {
        margin-top: 0px;
        margin-bottom: 0px;
        .formGroup{
            .formControlLabel {
                span {
                    line-height: unset;
                    font-size: 12px;
                }
            }
        }
    }
}