.hours {
    font-size: 10px;
    color: #868686;
    padding: 1px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        height: 14px;
    }
}

.difference {
    display: block;
    font-size: 10px;
    color: #868686;
}

.negative {
    color: #F5669A;
}

.positive {
    color: #63C2A0;
}