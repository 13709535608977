.root {
    height: 100%;
    transition: transform 0.3s  ease-in-out;
    overflow: hidden;
}

.block {
    height: 100%;
}

.dragging {
    opacity: 0.8;
    transform: rotate(1deg);
    z-index: 1;
}