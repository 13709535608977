
.iconCell {
    width: 100%;
    display: flex;
    align-items: center;

    .container {
        display: flex;
        justify-content: start;
        height: 100%;

        &.contentEnd {
            justify-content: end;
        }

        &.hasIcon, &:not(.iconText) {
            justify-content: space-between;
        }

        .iconComponentContainer {
            display: flex;
            align-items: center;
            margin-right: 8px;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        .icon {
            margin-right: 8px;
            width: 18px;
            height: 18px;
            color: #4F4F4F;
            fill: #4F4F4F;
        }
    }
}

.cellTooltip {
    .arrowWhite {
        width: 16px;
        height: 16px;
        color: #FFFF;
        fill: #FFFF;
    }
}
