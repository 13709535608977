@import '../../colors.scss';
$darkBlue: #003A78;

.root {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 0px 8px 8px 8px;
    box-sizing: border-box;
    margin-top: -4px;
    position: relative;
    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
        width: 9px;
        background-color: white;
    }
}

.figure {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 16px 18px;
    margin: 4px;
    box-sizing: border-box;
    min-width: calc(max(calc(25% - 8px), 240px));
    background-color: rgba(45, 159, 247, 0.06);
    border-radius: 5px;

    &:right {
        border: none !important;
    }
    h4 {
        margin: 0;
        margin-bottom: 2px;
        font-weight: 600;
        font-size: 12px;
        color: $darkBlue;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    h5 {
        margin: 0;
        margin-bottom: 2px;
        font-weight: 600;
        font-size: 12px;
        color: $darkBlue;
    }

    .value {
        font-weight: 600;
        font-size: 18px;
        color: $darkBlue;
        display: flex;
        align-items: center;
        gap: 10px;
        pointer-events: all;
    }

    .subvalue {
        font-size: 12px;
        color: $darkBlue;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        font-weight: bold;
    }

    // padding-left: 20px;
    // padding-right: 20px;

    .fill {
        // flex: 1;
        min-height: 8px;
    }
}

.editSlider {
    width: 500px;
    padding: 24px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;

    h2 {
        margin: 0;
        font-weight: 600;
        font-size: 18px;
    }

    p {
        margin: 0;
        font-weight: 600;
        color: $steel;
        margin-bottom: 16px;
    }

    .controls {
        padding-top: 24px;
        display: flex;
        justify-content: space-between;
    }

    button {
        text-transform: uppercase;
    }
}

.topbar {
    button {
        // text-transform: uppercase;
        color: $dark-sky-blue;
        svg {
            height: 16px;
            width: 16px;
            margin-right: 4px;
            color: $dark-sky-blue;
        }
        &:hover {
            background-color: rgba(45, 159, 247, 0.06);
        }
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    overflow: hidden;
}

.sliderFigures {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: min-content;
    gap: 10px;
    overflow: auto;
    flex: 1;
}

.iconContainer {
    padding: 0 5px;
    position: absolute;
    right: 8px;
    top: 8px;

    .icon {
        width: 20px;
        color: #d8d8d8;

        &.selected {
            color: $dark-sky-blue;
        }
    }
}

.editFigure {
    cursor: pointer;
    display: flex;
    flex: 1;
    border-radius: 5px;
    overflow: hidden;
    position: relative;

    .figure {
        pointer-events: none;
        user-select: none;
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 14px 16px;
        margin: 0;
        background: none;

        h4 {
            margin: 0;
        }
    }

    &.selected {
        .figure {
            background-color: rgba(45, 159, 247, 0.06);
        }
    }

    border: 1px dashed #dde3e8;
}

.progress {
    background: rgba(0,0,0,0.1);
    margin-top: 4px;
    border-radius: 2px;
    overflow: hidden;
    display: flex;

    > div {
        height: 16px;

        &.inner {
            background: #42b677;
        }
    }
}

.subfigure_progress {
    flex: 1;
    .content {
        flex: 1;
    }
}
