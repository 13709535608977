@import '../colors.scss';

#lockUsersView {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .lockingOverlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(255, 255, 255, 0.5);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .contactAdmin {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        > div {
            display: flex;
            flex-direction: column;
            align-items: center;
            h1 {
                margin-top: 24px;
            }
            p {
                margin-top: 6px;
                font-weight: bold;
                color: $steel;
            }
        }
    }
    h1,
    p {
        margin: 0;
        padding: 0;
    }
    .header {
        padding: 24px 32px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        button {
            white-space: nowrap;
        }
        h1 {
            font-size: 20px;
        }
        p {
            margin-top: 6px;
            font-weight: bold;
            font-size: 13px;
            color: $steel;
            button {
                border: none;
                font-weight: bold;
                font-family: 'Open Sans', sans-serif;
                padding: 0;
                margin: 0;
                color: $dark-sky-blue;
                background: none;
                cursor: pointer;
                font-size: 13px;
            }
            span {
                color: $faded-red;
                &.green {
                    color: $greenish-cyan;
                }
            }
        }
    }
}
