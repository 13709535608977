.category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 412px;
    min-height: 60px;
    margin-left: 12px;
    margin-right: 12px;
    cursor: pointer;

    .categoryTitle {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .title {
            margin-left: 16px;
            font-size: 14px;
            color: #2c3547;
            font-weight: 600;
        }

        .iconContainer {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-left: 12px;
            display: flex;
            justify-content: center;
            align-items: center;

            background-color: rgba(111, 66, 193, 0.2);

            svg {
                height: 18px;
                width: 18px;
            }
        }
    }

    .categoryActions {
        display: flex;
        margin-top: 20px;

        .notificationCount {
            padding: 0 4px;
            height: 22px;
            min-width: 22px;
            border-radius: 11px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 8px;
            text-overflow: ellipsis;
            overflow: hidden;
            font-weight: 600;
            color: #ffffff;
            font-size: 12px;
            box-sizing: border-box;

            &.active {
                background: #f7548f;
            }

            &.inactive {
                background: #b2b9c9;
            }
        }
    }
}

.categoryFunctions {
    margin-left: 70px;
    margin-right: 70px;
    margin-bottom: 14px;

    //margin-top: -16px;
    span {
        font-weight: 600;
        font-size: 12px;
        color: #2D9FF7;
        margin-right: 30px;
        cursor: pointer;
    }
}

.divider {
    width: 412px;
    height: 2px;
    background: #dde3e8;
    margin-left: 12px;
    margin-right: 12px;

    &.buffer {
        margin-top: 18px;
    }
}