@import '../colors.scss';

.settings {
    flex: 1;
    padding: 32px;
    align-self: stretch;
    box-sizing: border-box;
    .fields {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.emailOption {
    display: flex;
    flex-direction: column;
    h3,
    p  {
        margin: 0;
        padding: 0;
    }
    h3 {
        font-size: 14px;
    }
    p {
        font-size: 12px;
    }
}

.composer {
    width: 800px;
    height: 600px;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #dde3e8;
    box-sizing: border-box;
    padding: 32px;
    display: flex;
    flex-direction: column;
    textarea {
        flex: 1;
        resize: none;
        border: none;
        outline: none;
        font-family: 'Open Sans', sans-serif;
        padding: 16px 0;
    }
    .autoComplete {
        flex: 1;
        .input {
            margin: 0;
            > div {
                border: none;
                .chip {
                    height: 28px;
                    margin-left: 0;
                    margin-right: 4px;
                    svg  {
                        font-size: 16px;
                    }
                    span {
                        font-size: 11px;
                    }
                }
            }
        }
    }
    .attachments {
        display: flex;
        align-items: center;
        button {
            border: none;
            background: none;
            min-width: none;
            padding: 0;
            margin: 0;
            cursor: pointer;
            &:hover {
                .attachment {
                    background-color: rgba(45, 159, 247, 0.06);
                }
            }
        }
        .attachment {
            display: flex;
            align-items: center;
            border: 1px solid #eee;
            border-radius: 6px;
            padding: 8px 12px;
            margin-right: 8px;
            svg {
                height: 20px;
                width: 20px;
            }
            p {
                margin: 0;
                padding: 0;
                margin-left: 8px;
            }
        }
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        margin-bottom: 8px;
    }
    .field {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #dde3e8;
        min-height: 60px;
        box-sizing: border-box;
        p {
            color: #6b7897;
            margin: 0;
            padding: 0;
            margin-right: 5px;
        }
        input {
            border: none;
            outline: none;
            padding: 0;
            margin: 0;
            font-family: 'Open Sans', sans-serif;
            flex: 1;
        }
    }
}

.sent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2,
    p {
        margin: 0;
        padding: 0;
    }
    h2 {
        margin-top: 32px;
    }
    p {
        margin-top: 4px;
        font-weight: bold;
        color: $steel;
    }
}
