
@import '../../colors.scss';

.verifyEmail {
    padding: 50px;
    border-radius: 10px;
    >div {
        color: $scarlet;
    }
}
.loginField {
    width: 350px;
}
.loginButton {
    margin-top: 8px;
}
.loginView {
    padding-top: 500px;
    >div {
        border-radius: 10px;
    }
}