.row {
    box-sizing: border-box;
    padding-right: 24px;
    font-size: 12px;
    font-weight: bold;
}
.rowtitle {
    box-sizing: border-box;
    padding-left: 24px;
    font-size: 12px;
    font-weight: bold;
}

.invoice-material-block-summary-row {
    display: flex;
    padding-left: 24px;
    padding-right: 24px;
    align-items: center;
    > div {
        flex: 1;
        font-weight: bold;

    }

    .alignRight {
        > div {
            text-align: right;
        }
    }
}
