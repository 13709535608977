@import '../../colors.scss';

.root {
    display: flex;
    flex-direction: column;
}

.menuCheckbox {
    color: $dark-sky-blue;
    opacity: 1 !important;
}

.controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    .left {
        h1 {
            margin: 0;
            padding: 0;
            font-size: 18px;
        }
    }
    .right {
        :global {
            .insight-drop-down {
                .icon {
                    margin: 0;
                    margin-left: 2px;
                }
                button {
                    padding: 0;
                    background-color: transparent !important;
                    color: $dark !important;
                    svg {
                        color: $dark !important;
                        fill: $dark !important;
                        * {
                            color: $dark !important;
                            fill: $dark !important;
                        }
                    }
                }
            }
        }
    }
}

.grid {
    margin: 0;
    flex: 1;

    display: grid;
    column-gap: 10px;
    row-gap: 10px;

    grid-auto-rows: min-content;
    grid-auto-columns: 1fr;
}

.loadingPage {
    display: grid;
    grid-template-columns: 1fr min-content 1fr;
    grid-template-rows: 1fr min-content 1fr;

    .loading {
        grid-column: 2;
        grid-row: 2;
    }
}

.dragPlaceholder {
    > div.marker {
        border: 1px dashed #00000033;
        box-sizing: border-box;
    }
}

:global(body.react-draggable-transparent-selection) {
    .root {
        pointer-events: none;
    }
}
