@import '../colors.scss';

#quoteView {
    display: flex;
    background-color: #f9f9f9;
    height: calc(100vh - 55px - 55px - 41px);
    .main {
        flex: 1;
        background-color: #f9f9f9;
        overflow: hidden;
        .placeholder {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            background-color: #f9f9f9;
            padding: 32px;
            box-sizing: border-box;
            > div {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
                background-color: white;
                border-radius: 5px;
                img {
                    height: 240px;
                    width: 240px;
                    margin-bottom: 32px;
                }
                h2,
                p {
                    margin: 0;
                    padding: 0;
                }
                p {
                    font-weight: bold;
                    color: $steel;
                    margin-bottom: 24px;
                }
            }
        }
        .preview {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: hidden;
            background-color: #f9f9f9;
            > div:nth-child(2) {
                padding-top: 8px;
            }
            .actions {
                width: 732px;
                max-width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                padding: 16px 16px 12px 16px;
                background-color: #f9f9f9;
                > label {
                    margin-right: 4px;
                    margin-bottom: -9px;
                    margin-left: -9px;
                    > span {
                        display: flex;
                        align-items: center;
                        font-size: 12px;
                        svg {
                            height: 20px;
                            width: 20px;
                        }
                    }
                }
                > div {
                    display: flex;
                    align-items: center;
                    button {
                        margin-left: 12px;
                    }
                }
            }
        }
    }
    .right {
        width: 400px;
        height: 100%;
        box-sizing: border-box;
        background-color: white;
        border-left: 1px solid #dde3e8;
        padding: 32px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        > h2,
        p {
            margin: 0;
            padding: 0;
        }
        > p {
            font-weight: bold;
            color: $steel;
            margin-top: 16px;
        }
        h2 {
            &:not(:first-of-type) {
                margin-top: 32px !important;
            }
        }
        .top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            > div {
                display: flex;
                flex-direction: column;
            }
        }
        .top,
        .quotes {
            h2,
            h3,
            h4,
            p {
                margin: 0;
                padding: 0;
            }
            h2 {
                font-size: 20px;
            }
            h3 {
                font-size: 18px;
            }
            h4 {
                font-weight: normal;
                font-size: 14px;
            }
            p {
                font-weight: bold;
                color: $steel;
                margin-top: 2px;
            }
        }
        .quotes {
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow: hidden;
            margin-top: 16px;
            ul {
                flex: 1;
                overflow: auto;
                list-style: none;
                padding: 0;
                margin: 0;
                margin-top: 8px;
                li {
                    display: flex;
                    flex-direction: column;
                    padding: 12px 0px;
                    cursor: pointer;
                    border-bottom: 1px solid #eee;
                    p {
                        font-size: 12px;
                    }
                    &.selected {
                        h4 {
                            font-weight: bold;
                        }
                    }
                    &:hover {
                        background-color: rgba(45, 159, 247, 0.06);
                    }
                }
            }
        }
    }
}
