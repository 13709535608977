.root {
    display: flex;
    padding: 10px;
    box-sizing: border-box;
}

.table {
    width: max-content;
}

.graph {
    padding: 0 20px;
    flex: 1;
    height: 400px;
}