@import '../colors.scss';

#buyLicensesSlider {
    min-width: 500px;
    max-width: 90vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    h1,
    p,
    h2 {
        margin: 0;
        padding: 0;
    }
    .top {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px;
        > button {
            background-color: transparent;
            min-width: unset;
            margin-right: -16px;
        }
    }
    .content {
        flex: 1;
        overflow: hidden;
        margin-top: -24px;
        background-color: white;
        width: 600px;
        max-width: 90vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        box-sizing: border-box;
        h1,
        p,
        h2 {
            margin: 0 !important;
            padding: 0 !important;
        }
        > img {
            width: 300px;
            max-width: 100%;
            max-height: 50%;
            min-height: 160px;
            margin: 0;
            align-self: center;
        }
        .header {
            display: flex;
            flex-direction: column;
            padding: 32px;
            box-sizing: border-box;
            flex: 1;
            overflow: auto;
            margin-top: 16px;
            h1 {
                font-weight: bold;
                font-size: 26px;
            }
            p {
                color: $steel;
                font-size: 16px;
                margin-top: 12px !important;
            }
            .italic {
                font-style: italic;
                margin-top: 16px !important;
            }
        }
        .actions {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 32px;
            > button {
                margin-left: 8px;
                height: 40px;
            }
        }
    }
}
