@import '../colors.scss';

.stepIndicator {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: #f9f9f9;
    .step {
        flex: 1;
        margin-right: 8px;
        background-color: $light-sky-blue;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 4px;
        p {
            margin: 0;
            padding: 0;
            font-size: 9px;
            color: $steel;
            font-weight: bold;
        }
        &.current {
            background-color: $dark-sky-blue;
            p {
                color: white;
            }
        }
        &.completed {
            background-color: $greenish-cyan;
            p {
                color: white;
            }
        }
    }
}
