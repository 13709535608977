@import '../colors.scss';
@import '../_theme.scss';

.menuItem {
    position: relative;
    .main {
        padding: 4px 8px;
        box-sizing: border-box;
        cursor: pointer;
        :global {
            .menu-item {
                &.tablet-mode {
                    overflow: visible !important;
                }
            }
        }
        :global {
            .name-container {
                flex: 1;
                min-width: 0;
                text-overflow: ellipsis;
                overflow: hidden;
                &.has-notification {
                    overflow: visible;
                }

                .notificationBadge {
                    max-width: 90%;
                    text-overflow: ellipsis;
                    
                    span {
                        z-index: 10000;
                        min-width: 16px;
                        height: 16px;
                        font-size: 9px;
                        margin-right: -12px;
                        margin-top: 2px;
                        color: #ffffff;
                        background-color: #f7548f;
                    }
                }
                &.tablet-mode {
                    flex: unset;
                    .notificationBadge {
                        span {
                            margin-top: -8px;
                            margin-right: -4px;
                        }
                    }
                }
            }

            box-sizing: border-box;

            span.count {
                margin-left: 5px;
                margin-right: 2px;
                font-size: 12px;

                &.limit-reached {
                    color: #f97fab;
                }
            }

            div.lock,
            div.star {
                display: flex;
                align-items: center;
            }

            div.lock {
                svg {
                    fill: white;
                    height: 18px;
                    width: 18px;
                }
            }
            div.star {
                svg {
                    color: $orangey-yellow !important;
                    fill: $orangey-yellow !important;
                    height: 14px;
                    width: 14px;
                    opacity: 1 !important;
                }
            }
            div.trial {
                svg {
                    fill: white;
                    height: 25px;
                    width: 25px;
                    g {
                        fill: #b2b9c9;
                    }
                }
            }
        }
    }
    > .main {
        &:hover,
        &:active {
            :global(.menu-item) {
                @include themedModule() {
                    background-color: t('darkHoverBG');
                    color: t('sidebarItemActive');
                }
                :global(.name) {
                    @include themedModule() {
                        color: t('sidebarItemActive');
                    }
                }
                :global(.icon-container) {
                    svg {
                        @include themedModule() {
                            fill: t('sidebarItemActive') !important;
                            color: t('sidebarItemActive') !important;
                        }
                        * {
                            @include themedModule() {
                                fill: t('sidebarItemActive') !important;
                                color: t('sidebarItemActive') !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .hoverMenu {
        position: fixed;
        @include themedModule() {
            background: t('sidebarBG');
            border: 1px solid t('sidebarBorder');
        }
        border-radius: 10px;
        z-index: 999999;
        padding-bottom: 4px;
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
        > .menuItem:nth-of-type(2) {
            > .main {
                padding-top: 8px;
            }
        }
        box-sizing: border-box;
        &.tabletMode {
            .hoverMenuTitle {
                display: flex;
            }
        }
        .hoverMenuTitle {
            position: absolute;
            top: -34px;
            width: 100%;
            border-radius: 10px;
            z-index: 0;
            box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.5);
            @include themedModule() {
                background: t('sidebarItemActiveBG');
                border: 1px solid t('sidebarBorder');
            }
            height: 30px;
            display: flex;
            align-items: center;
            padding: 0 20px;
            box-sizing: border-box;
            display: none;
            h3 {
                padding: 0;
                margin: 0;
                @include themedModule() {
                    color: t('sidebarItemActive');
                }
                font-size: 12px;
            }
        }
        .main {
            :global {
                .menu-item {
                    .name {
                        margin-left: 20px;
                        min-width: 200px;
                        padding-right: 50px;
                    }
                }
            }
        }
    }
}
