@import '../colors.scss';

.delete {
    min-width: 0;
    padding: 8px;
    margin: 0;
    background: transparent;
    svg {
        height: 20px;
        width: 20px;
    }
    &:hover {
        background-color: #eee;
    }
}

.borderless {
    border-width: 0px;
    * {
        border-width: 0px;
    }
    label {
        color: $dark !important;
        font-weight: 500;
        top: 6px
    }
}

.addAddress {
    display: flex;
    align-items: center;
    height: 45px;
    padding: 8px 16px;
    color: $dark;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
        background-color: rgba(45, 159, 247, 0.06);
    }
    svg {
        color: $dark;
        margin-right: 6px;
        height: 20px;
        width: 20px;
    }
}

.settings {
    flex: 1;
    padding: 32px;
    align-self: stretch;
    box-sizing: border-box;
    .fields {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
    }
    h2 {
        margin: 0;
        padding: 0;
        font-size: 16px;
    }
}

.sent {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    h2,
    p {
        margin: 0;
        padding: 0;
    }
    h2 {
        margin-top: 32px;
    }
    p {
        margin-top: 4px;
        font-weight: bold;
        color: $steel;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.explainer {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 16px 32px;
    > h2 {
        font-size: 20px;
    }
    // .title {
    //     p {
    //         font-size: 12px;
    //     }
    //     svg {
    //         height: 18px;
    //         width: 18px;
    //     }
    // }
    div[role="radiogroup"] {
        label {
            justify-content: space-between;
            margin-left: 0;
            font-size: 14px;
        }
    }
}

.tabQuotes {
    display: none;
}

.greyButton {
    background-color: #eee;
    color: $dark;
    &:hover {
        background-color: #bbb;
    }
}

.box {
    background-color: white;
    width: 100%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    border: 1px solid #eee;
    padding: 30px;
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    box-sizing: border-box;
    &.fieldWithDetails {
        :global(.datalist-container) {
            margin-bottom: 16px;
        }
    }
}
.title {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    h2 {
        font-size: 20px;
    }
    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 16px;
        background: rgba(111, 66, 193, 0.08);
        width: 40px;
        height: 40px;
        > svg {
            height: 20px;
            width: 20px;
            fill: #6f42c1;
        }
    }
    h2 {
        margin: 0;
        padding: 0;
    }
    .right {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 1 !important;
        h2 {
            flex: 1;
        }
        > svg {
            margin-left: 8px;
            width: 20px;
            height: 20px;
            color: $steel;
        }
        p {
            margin: 0;
            padding: 0;
            font-weight: bold;
            color: $steel;
        }
        > div {
            margin-top: 2px;
            display: flex;
            align-items: center;
            svg {
                height: 20px;
                width: 20px;
                margin-right: 4px;
            }
        }
    }
}

.fieldExplainers {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;
    padding: 8px 0;
    h2,
    p {
        margin: 0;
        padding: 0;
    }
    h2 {
        font-size: 18px;
        margin-top: 16px;
    }
    p {
        color: $steel;
        font-weight: bold;
    }
}

.addmenu {
    margin-top:8px;
    > div {
        margin: 0;
        margin-left: 0px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .wizard-row-add__menu {
            min-width: max-content !important;
        }
        :global {
            .datalistValue {
                cursor: pointer;
            }
        }
        & > div {
            border: 0;
        }
        span {
            padding: 0 !important;
        }
        svg {
            margin-right: -22px;
            background: none;
            fill: $dark-sky-blue;
        }
        input {
            pointer-events: none !important;
        }
        p,
        label {
            font-size: 12px;
            color: #2d9ff7;
            font-weight: 600;
            text-overflow: ellipsis;
            overflow: hidden;
            pointer-events: none;
        }
    }
}

#statisticsInfoGroup {
    background-color: #f9f9f9;
    > div {
        text-indent: 20px;
        padding: 4px 0;
        &.infoChip {
            padding: 8px 0;
        }
    }
}

.content {
    width: 100%;
    max-width: 100%;
    height: fit-content;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 32px;
    box-sizing: border-box;
    .loader {
        width: 100px;
        height: 100px;
    }
    .bulkCounter {
        color: $faded-red;
    }
    :global(.date) {
        height: 47px;
        margin-top: 8px;
    }
    .rowEditor {
        margin-top: 8px;
        > span {
            display: block;
            height: 8px;
        }
        .headerContainer {
            display: flex;
            align-items: center;
            button {
                margin: 0 !important;
                margin-right: -8px !important;
                margin-left: 8px !important;
                svg {
                    margin: 0 !important;
                }
            }
        }
        input {
            width: 100%;
            border: none;
            outline: none;
            font-size: 16px;
            background: none;
            padding: 0;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            box-sizing: border-box;
        }
        table {
            margin: 8px -8px 0 -8px;
            width: calc(100% + 16px);
            thead {
                th {
                    padding: 8px;
                    border: none;
                    font-weight: bold;
                    border-bottom: 1px solid #eee;
                    white-space: nowrap;
                    &.orderContainer {
                        padding: 0;
                    }
                }
            }
            tbody {
                > span {
                    display: block;
                    height: 8px;
                }
                > p {
                    margin: 8px;
                    font-weight: bold;
                    color: $steel;
                }
                tr {
                    border-radius: 10px;
                    position: relative;
                    min-height:44px;
                    td {
                        padding: 4px 8px;
                        border: none;
                        white-space: nowrap;
                        max-width: 200px;
                        position: relative;                     
                        &:not(.orderContainer) {
                            cursor: pointer;
                        }
                        &.orderContainer {
                            padding: 0;
                            .order {
                                height: 100%;
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                overflow: hidden;
                                button {
                                    background: none;
                                    border: none;
                                    padding: 0;
                                    min-width: 0;
                                    width: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    height: 16px;
                                    cursor: pointer;
                                    border-radius: 5px;
                                    &:hover {
                                        background-color: #eee;
                                    }
                                    svg {
                                        height: 20px;
                                        width: 20px;
                                    }
                                }
                            }
                        }
                        &:first-child {
                            border-top-left-radius: 10px;
                            border-bottom-left-radius: 10px;
                            white-space: normal;
                        }
                        &:last-child {
                            border-top-right-radius: 10px;
                            border-bottom-right-radius: 10px;
                        }
                        // font-weight: bold;
                        .description {
                                margin: auto;
                                white-space: initial;
                        }
                    }
                }
            }
            .itemCell {
                p {
                    margin: 0;
                    padding: 0;
                    white-space: normal;
                    word-wrap: break-word;
                    &:first-of-type {
                        font-weight: bold;
                    }
                }
            }
            .tooltipIcon {
                color: $dark-sky-blue;
                width: 20px;
            }
        }
        .grandTotal {
            position: relative;
            background-color: #f4f5f8 !important;
            margin: 16px -32px -32px -32px;
            padding: 16px 32px;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            page-break-inside: avoid !important;
            height: fit-content;
            box-sizing: border-box;
            width: calc(100% + 64px);
            h2,
            h3,
            p {
                padding: 0;
                margin: 0;
                font-size: 12px;
            }
            h2 {
                font-size: 16px;
                font-weight: 600;
            }
            h3 {
                font-size: 14px;
                font-weight: 600;
            }
            .totalValues {
                display: flex;
                align-items: flex-start;
                max-width: 600px;
                margin-left: 32px;
                > div {
                    flex-shrink: 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: flex-start;
                    margin-left: 32px;
                    text-align: right;
                    .totalValue {
                        height: 38px;
                    }
                    .vatTotals {
                        display: flex;
                        flex-direction: column;
                        hr {
                            width: 100%;
                            height: 1px;
                            border: none;
                            background-color: $steel;
                            opacity: 0.5;
                            margin-block-start: 8px;
                            margin-block-end: 8px;
                        }
                        .vatTotalRow {
                            font-style: italic;
                            font-size: 11px;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            p {
                                text-align: right;
                                &:first-of-type {
                                    width: 50px;
                                    margin-right: 8px;
                                }
                            }
                        }
                    }
                    p {
                        white-space: nowrap;
                    }
                }
            }
        }
        .actions {
            display: flex;
            align-items: center;
            button {
                background: none;
                border: none;
                display: flex;
                align-items: center;
                color: $dark-sky-blue;
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                cursor: pointer;
                border-radius: 5px;
                padding: 4px 8px;
                transition: background-color 300ms ease-in-out;
                width: fit-content;
                margin-top: 3px;
                margin-bottom: 1px;
                margin-left: -8px;
                margin-right: 8px;
                font-size: 12px;
                &:hover {
                    background-color: rgba(45, 159, 247, 0.06);
                }
                p {
                    margin: 0;
                }
                svg {
                    color: $dark-sky-blue;
                    height: 20px;
                    width: 20px;
                    margin-right: 6px;
                }
            }            
        }
    }
    .description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 24px;
        text-align: left;
        h2 {
            margin: 0;
            padding: 0;
            color: $dark;
            font-size: 22px;
        }
        p {
            margin: 0;
            margin-top: 4px;
            color: $steel;
            font-size: 16px;
            font-weight: bold;
        }
        svg {
            height: 20px;
            width: 20px;
            margin-right: 6px;
        }
    }
    :global(.no-my-day-drag) {
        height: unset !important;
        border: solid 1px #c3cbdb;
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        overflow: hidden !important;
    }
    &.scrollable {
        overflow: auto;
    }
    .animation {
        position: absolute;
        pointer-events: none;
        left: 50%;
        top: 50%;
        transform: translateY(-35%) translateX(-40%);
    }
    .timer {
        padding: 24px;
        background: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px #c3cbdb;
        box-shadow: 0 2px 24px 0 rgba(0, 0, 0, 0.05);
        > div {
            padding: 8px;
            width: 100%;
            svg {
                height: 50px;
                width: 50px;
            }
            p {
                font-size: 14px;
                margin-top: 4px;
            }
        }
    }
    .fieldContainers {
        display: flex;
        > div {
            flex: 1;
            display: flex;
            flex-direction: column;
            margin-right: 8px;
            &:last-of-type {
                margin-right: 0;
            }
            h2 {
                margin: 0;
                margin-bottom: 8px;
                padding: 0;
                font-size: 18px;
            }
        }
    }
    &.quoteActions {
        > div {
            .addButton {
                margin: -8px 0 20px -4px;
                font-size: 16px !important;
                svg {
                    height: 22px;
                    width: 22px;
                }
            }
        }
    }
    > div {
        max-width: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        .secondaryButton {
            background: none;
            border: none;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            color: #6b7897;
            cursor: pointer;
            border-radius: 5px;
            padding: 4px 8px;
            transition: background-color 300ms ease-in-out;
            display: flex;
            align-items: center;
            margin-top: 8px;
            svg {
                color: $steel;
            }
            &:hover {
                background-color: #eee;
            }
        }
        .addButton {
            background: none;
            border: none;
            display: flex;
            align-items: center;
            color: $dark-sky-blue;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            cursor: pointer;
            border-radius: 5px;
            padding: 4px 8px;
            transition: background-color 300ms ease-in-out;
            width: fit-content;
            margin-top: 3px;
            margin-left: -4px;
            margin-bottom: 16px;
            font-size: 12px;
            &:hover {
                background-color: rgba(45, 159, 247, 0.06);
            }
            p {
                margin: 0;
            }
            svg {
                color: $dark-sky-blue;
                height: 20px;
                width: 20px;
                margin-right: 6px;
            }
        }
        .row {
            display: flex;
            align-items: center;
            width: 100%;
            box-sizing: border-box;
            align-self: center;
            > button {
                background: none;
                border: none;
                display: flex;
                align-items: center;
                color: $steel;
                font-family: 'Open Sans', sans-serif;
                font-weight: bold;
                cursor: pointer;
                border-radius: 5px;
                padding: 4px;
                transition: background-color 300ms ease-in-out;
                width: fit-content;
                font-size: 12px;
                margin-left: 8px;
                &:hover {
                    background-color: rgba(45, 159, 247, 0.06);
                }
                p {
                    margin: 0;
                }
                svg {
                    color: $steel;
                    height: 20px;
                    width: 20px;
                }
            }
            > div {
                &:first-of-type {
                    flex: 1;
                    margin-right: 16px;
                }
                &:last-of-type {
                    width: 100px;
                }
            }
        }
    }
}
