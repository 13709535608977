.dialogList {
    .mainListWrapper {
        .header {
            padding-left: unset !important;
            background: #F7F7F7 !important;
            height: 26px !important;
            line-height: 26px !important;

            .titleSpan {
                line-height: 26px !important;
                padding-left: 6px !important;
                margin-right: 7px !important;
            }

             .column {
                color: black;
            }
        }
    }

    .mainListWrapper {
        > .header {
            .column {
                background: #F7F7F7 !important;
            }  
        }
    }

    .dialogRow {
        margin-top: 5px !important;
        height: 26px !important;
        line-height: 26px !important;

        .hiddenCell {
            display: none !important;
        }
    
        .dialogCell {
            margin-right: 7px !important;
            margin-left: 5px !important;

            .cellValue {
                padding: 0px;
                &.alignRight {
                    padding-right: 0px !important;
                }
            }

            &.alignRight {
                .inner {
                    text-align: right;
                    padding-right: 7px !important;
                }
            }

            &.editMode {
                border: 1px dashed #c3cbdb !important;
                border-radius: 5px;

                .inner {
                    display: flex;
                    align-items: center;
                }

                input, .cellValue {
                    padding: 0px 7px;
                }

                input {
                    height: 100%;
                    border-radius: 5px;

                    &.errorBorder {
                        border: 1px dashed #dc4054 !important;
                    }
                }

                &.disabled {
                    background-color: #f9f9f9 !important;
                    color: #2c3547 !important;
                    cursor: not-allowed;
    
                    .cellValue {
                        &.alignRight {
                            padding-right: 7px !important;
                        }
                    }
    
                    .inner {
                        div {
                            width: 100%;
                        }
                    }
                }
            }

            .dateWrapper {
                .inputWrapper {
                    display: flex;
                    align-items: center;

                    .datePickerInput {
                        padding-left: 7px !important;
                        font-size: 12px !important;
                    }

                    svg {
                        width: 20px !important;
                        margin-left: 10px !important;
                    }
                }

                &.errorBorder {
                    .inputWrapper {
                        border: 1px dashed #dc4054 !important;
                        border-radius: 5px;
                        height: 100%;
                    }
                }
            }
        }
    }
    
    .totalsRow {
        margin-top: 5px !important;
        height: 26px !important;
        line-height: 26px !important;
    
        .dialogCell {
            margin-right: 7px !important;

            .cellValue {
                padding: 0px;
                font-weight: bold !important;
                padding-left: 7px !important;

                &.alignRight {
                    padding-right: 0px !important;
                }
            }

            &.alignRight {
                .inner {
                    text-align: right;
                    padding-right: 7px !important;
                }
            }
        }
    }
}
