@import '../theme.scss';

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.sliderList {
    margin-bottom: 16px;

    &.previewMode {
        & > div > div {
            border: none !important;
        }
    }
}

.attachmentSliderList {
    td {
        padding: 8px !important;
    }
}

.rowPreviewBox {
    border: none !important;
    box-shadow: none !important;
    padding: 0 30px 16px 30px !important;
}

#userLink {
    font-size: 13px !important;
}

#detailsNumberContainer {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 16px;
}

.titleRowParagraph {
    display: block;
    margin: 1px 0 1px 0;
}

.detailsTopSection {
    padding-top: 24px !important;
}

.detailsTopSectionTopRow {
    justify-content: unset !important;
}

#createRowsMainContent {
    background: transparent;
    box-shadow: none;
}

#details {
    padding: 24px;
    @include themedModule() {
        background: t('lightBG');
    }
    display: flex;
    flex-wrap: wrap;
    .savingOverlay {
        background-color: rgba(255,255,255,0.7);
        z-index: 99;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        animation: fadeIn 300ms ease-in-out;
    }
    .detailsSections {
        .detailsSection {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            :global {
                .dateWrapper {
                    height: 49px;
                    margin: 6px 0;
                }
            }
        }
    }
    .listContainer {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        flex: 1;
        h3 {
            padding: 16px 24px;
            flex-shrink: 0;
        }
        > div {
            flex: 1;
            overflow: hidden;
        }
        :global {
            .dateWrapper {
                width: 100%;
                .inputWrapper {
                    input {
                        // Sorry if this was something important.
                        // width: 90% !important;
                    }
                }
            }
        }
    }
}

.mainContent {
    background: none;
    box-shadow: none;
}

.additionalContentLink {
    font-size: 12px;
}

.dayContainer {
    margin-bottom: 16px;
    margin-top: 16px;
}

.mealFieldEditSlider {
    width: 500px;
}

// #id allows us to not use !important here.
// There should never be more than one of these 
// in the DOM anyway.
#mealFieldEditSliderFields {
    padding: 6px 0 0 0;
}

.mealFieldEditSliderSummary {
    background: #f4f4f4;
    display: flex;
    flex-direction: row;
    height: 60px;
    box-sizing: border-box;
    padding: 8px 16px;
    margin-bottom: 16px;

    .col {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        
        .row {
            flex: 1 1;
        }

        &.alignRight {
            text-align: right;
        }
    }
}

.mealGrid {
    display: grid;
    grid-template-columns: 18% ;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
}

.mealText { 
    grid-area: 1 / 1 / 2 / 2; 

    p {
        font-weight: bold;
        font-size: 12px;
    }
}

.mealButtons { grid-area: 1 / 2 / 2 / 3; } 
.mealButton { width: 32px; min-width: 32px; max-width: 32px; }
.mealSeparator { 
    width: 100%;
    height: 1px;
    background: #e3e3e3;
    margin-bottom: 8px;
    margin-top: 8px;
}

.mealDateContainer {
    display: inline-block;
    width: 80%;
    height: 40px;
    float: left; /* Sorry for this */

    h2 {
        font-size: 14px;
    }
}

%mealTitlePart {
    display: inline-block;
    margin-bottom: 0px;
}

%dayPart {
    width: 100%;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    margin: 0px;
}

.dayOrderAndLength {
    @extend %dayPart;
    font-weight: 600;
}

.mealDate {
    @extend %mealTitlePart;
    @extend %dayPart;
    font-weight: 300;
}

.mealP {
    font-size: 13px;
}

.mealTotal {
    @extend %mealTitlePart;

    vertical-align: middle;
    margin: 0;
    padding: 0;
    line-height: 40px;
    height: 40px;
    text-align: right;
    color: #a7a7a7;
    width: 20%;
}

.sidePaddingContainer {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 8px;
}

.additionalCellLeft {
    display: inline-block;
    margin-right: 8px;
}

.additionalCellRight {
    display: inline-block;
}

.childArrowIcon {
    margin-top: -26px;
}

.fileInput {
    display: none;
}

.linkLikeButton {
    font-family: unset;
    color: $dark-sky-blue;
    font-size: 12px;
    background: none;
    border: 0;
    outline: 0;
    cursor: pointer;
    padding: 0;

    &:hover {
        text-decoration: underline;
    }
}

.attachFileIcon {
    color: #fff;
    font-size: 14px;
}

.attachmentIcon {
    color: #2d9ff7;
    font-size: 18px;
}

.attachmentIconText {
    color: #2d9ff7;
    font-weight: 900;
}

.attachmentTooltipList {
    padding: 0px;
    list-style-type: none;
    width: 250px;

    li {
        span:nth-of-type(1) {
            display: inline-block;
            width: 70%;
            font-size: 12px;
        }

        span:nth-of-type(2) {
            display: inline-block;
            width: 30%;
            text-align: right;

            button {
                font-size: 12px;
            }
        }
    }
}

.pointer {
    cursor: pointer;
}

.avatar {
    background: #6B7897;
}
