
@import '../../colors.scss';

.ErrorsListContainer{   
    max-height: 300px;
    overflow: auto;
    .errorList {
        padding: 10px;
        margin: 0px;
        background-color: rgba(247, 84, 143, 0.1);
        font-size: 12px;
        border-radius: 5px;

        .row {
            display: flex;
            flex-direction: row;
            padding: 0px;
            color: #F7548F;
            padding-top: 5px;
            align-items: center;

            .header {
                font-weight: 600;
                margin-right: 5px;
                white-space: nowrap;
            }

            .link {
                cursor: pointer;
                color: #2D9FF7;
                font-weight: 600;
            }

            svg {
                fill: #F7548F;
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }
        }

        .row:first-child {
            padding-top: 0px;
        }

        &.warning {
            background-color: #fce8c7;
            .row {
                color: #c38115;
            }
            svg {
                fill: $orangey-yellow;
            }
        }

        &.warning-light {
            background-color: rgba(255, 166, 33, 0.1);
            .row {
                color: #FFA621;
            }
            svg {
                fill: #FFA621;
            }
            padding-top: 8px;     
            padding-bottom: 8px;     
        }
    }
}