.sliderContent {
    width: 600px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .content {
        flex: 1;
        overflow: auto;
        padding: 16px;

        .iconTextButton {
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: 5px;
            span {
                color: #2D9FF7;
                font-weight: bold;
                margin-left: 7px;
            }
            svg {
                color: #5F9BF1;
                width: 21px;
                height: 21px;
            }
            &.disabled {
                cursor: default;
                opacity: 0.5;
            }
        }

        .section {
            margin-top: 12px;
            border-bottom: 1px solid #eee;
            padding-bottom: 12px;
    
            &:last-child {
                border-bottom: none;
            }
            &:first-child {
                margin-top: 0;
            }
    
            .percentageField {
                width: 100%;
            }

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 1;
                .title {
                    font-size: 12px;
                    font-weight: bold;
                }
                .remove {
                    cursor: pointer;
                    svg {
                       width: 21px;
                       height: 21px;
                    }
                }
            }
            :global {
                .limit-absolute-positioning {
                    top: 16px; // Take into account top margin of field.
                }
            }
        }
    }
}
