@import '../colors.scss';

#taimerWizard {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9;
    &.loading {
        pointer-events: none;
    }
    .topBar {
        height: 60px;
        width: 100%;
        background-color: white;
        border-bottom: 1px solid #dde3e8;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        box-sizing: border-box;
        position: relative;
        .center {
            display: flex;
            align-items: center;
            position: relative;
            button {
                position: absolute;
                left: -32px;
                min-width: 0;
                padding: 8px;
                background-color: transparent;
                &:hover {
                    background-color: #d5d5d5;
                }
            }
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                margin: 0 24px;
                h1,
                p {
                    margin: 0;
                    padding: 0;
                }
                h1 {
                    font-size: 17px;
                    color: $dark;
                }
                p {
                    font-size: 13px;
                    color: $steel;
                    font-weight: bold;
                }
            }
        }
        .closeButton {
            position: absolute;
            left: 16px;
            min-width: 0;
            padding: 8px;
            background-color: transparent;
            &:hover {
                background-color: #d5d5d5;
            }
        }
        .actions {
            position: absolute;
            right: 16px;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            > button {
                margin-left: 8px;
                height: 40px !important;
            }
        }
    }
    .stepIndicator {
        margin: 24px 0 16px 0;
    }
    .content {
        flex: 1;
        align-self: stretch;
        display: flex;
        overflow: hidden;
        // border-top: 1px solid #dde3e8;
        .box {
            background-color: white;
            width: 100%;
            box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.05);
            border-radius: 5px;
            border: 1px solid #eee;
            padding: 30px;
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;
            box-sizing: border-box;
            &.fieldWithDetails {
                :global(.datalist-container) {
                    margin-bottom: 16px;
                }
            }
        }
        .title {
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            h2 {
                font-size: 20px;
            }
            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                margin-right: 16px;
                background: rgba(111, 66, 193, 0.08);
                width: 40px;
                height: 40px;
                > svg {
                    height: 20px;
                    width: 20px;
                    fill: #6f42c1;
                }
            }
            h2 {
                margin: 0;
                padding: 0;
            }
            .right {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                line-height: 1 !important;
                h2 {
                    flex: 1;
                }
                > svg {
                    margin-left: 8px;
                    width: 20px;
                    height: 20px;
                    color: $steel;
                }
                p {
                    margin: 0;
                    padding: 0;
                    font-weight: bold;
                    color: $steel;
                }
                > div {
                    margin-top: 2px;
                    display: flex;
                    align-items: center;
                    svg {
                        height: 20px;
                        width: 20px;
                        margin-right: 4px;
                    }
                }
            }
        }
        .main {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            overflow: auto;
            &.fullWidth {
                display: block;
                padding: 50px;
                width: -webkit-fill-available;
            }
        }
        .secondaryAction {
            background: none;
            border: none;
            display: flex;
            align-items: center;
            color: $dark-sky-blue;
            font-family: 'Open Sans', sans-serif;
            font-weight: bold;
            cursor: pointer;
            border-radius: 5px;
            padding: 4px 8px;
            transition: background-color 300ms ease-in-out;
            width: fit-content;
            font-size: 12px;
            margin-top: 8px;
            margin-left: -8px;
            margin-right: 8px;
            font-size: 12px;
            &:hover {
                background-color: rgba(45, 159, 247, 0.06);
            }
            p {
                margin: 0;
            }
            svg {
                color: $dark-sky-blue;
                height: 20px;
                width: 20px;
                margin-right: 6px;
            }
        }
        .secondary {
            width: 400px;
            height: 100%;
            background-color: white;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            h1 {
                font-size: 24px;
            }
            &.left {
                border-right: 1px solid #dde3e8;
            }
            &.right {
                border-left: 1px solid #dde3e8;
            }
        }
        &.even {
            .secondary {
                width: unset;
                flex: 1;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    #taimerWizard:not(.hideMainWhenSmall) {
        .content {
            .secondary {
                display: none;
            }
        }
    }
    #taimerWizard.hideMainWhenSmall {
        .content {
            .main {
                display: none;
            }
        }
    }
}
