@import '../colors.scss';

.testModeIndicator {
    background-color: rgb(208, 190, 238);
    padding: 8px 16px;
    border-radius: 5px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    color: #6f42c1;
    font-size: 13px;
    user-select: none;
    margin-right: 16px;

    svg {
        color: #6f42c1;
        margin-right: 6px;
        margin-left: -2px;
        height: 20px;
        width: 20px;
    }
}


.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .toolbar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 16px 12px 16px;
        margin-bottom: -16px;
        background-color: white;
        z-index: 2;

        .badge {
            span {
                min-width: 16px;
                height: 16px;
                font-size: 9px;
                margin-right: 2px;
                margin-top: 2px;
                color: #ffffff;
                background-color: $dark-sky-blue;
            }
        }

        button {
            border: none;
            background: none;
            font-family: 'Open Sans', sans-serif;
            color: $dark-sky-blue;
            font-weight: bold;
            font-size: 12px;
            cursor: pointer;
            padding: 6px 8px;
            border-radius: 5px;
            margin-left: -6px;

            &:hover {
                background-color: rgba(45, 159, 247, 0.06);
            }
        }

        .viewSettingsButton {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6px 8px;
            border-radius: 5px;
            cursor: pointer;
            border: 1px solid $border;

            &:hover {
                background-color: rgba(45, 159, 247, 0.06);
            }

            svg {
                height: 18px;
                width: 18px;
                color: $dark-grey-blue;

                &:last-child {
                    margin-right: -6px;
                }
            }

            >* {
                pointer-events: none;
            }
        }
    }
}

.previousEntries {
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);

    .topBar {
        height: 40px;
        background-color: $dark-grey-blue;
        display: flex;
        align-items: center;
        color: white;
        padding: 8px 16px;
        flex-shrink: 0;
        -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);

        h1 {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 500;
            flex: 1;
        }
    }


    .gridEntryContainer {
        display: grid;
        //grid-template-columns: 50%, 50%;
        grid-template-columns: repeat(auto-fill, minmax(50%, 50%));
        overflow-y: auto;

        .highlightContainer {
            display: flex;
            align-items: center;
            padding: 10px 15px;
            cursor: pointer;
            border-radius: 10px;

            &:hover {
                background: rgba(45, 159, 247, 0.06);
            }

            .titles {
                display: flex;
                flex-direction: column;
                flex: 1;
                width: 50%; // Don't know why this works, but it does.

                .task {
                    color: $steel;
                }

                > h3, p {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .description {
                    font-style: italic;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                p {
                    &:first-of-type {
                        font-weight: bold;
                        color: #3a455b;
                    }
                }
            }

            button {
                text-transform: uppercase;
                background-color: transparent;
                color: $dark-sky-blue;

                &:hover {
                    background-color: transparent !important;
                }
            }

            p,
            h3 {
                margin: 0;
                padding: 0;
                font-size: 12px;
            }
        }
    }



    .previousEntrySettings {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        background-color: $pale-grey-three;
        padding: 4px 4px;
        -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);

        label {
            margin-left: 0;
            margin-right: 4px;

            >span {
                display: flex;
                align-items: center;
                font-size: 12px;

                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    img {
        width: 80px;
        height: 80px;
        margin-top: 12px;
        margin-left: 12px;
    }


    ul {
        list-style: none;
        padding: 10px 0;
        margin: 0;
        overflow: auto;
        flex: 1;

        li {
            padding: 0px 12px;

            .highlightContainer {
                display: flex;
                align-items: center;
                padding: 10px 12px;
                cursor: pointer;
                border-radius: 10px;

                &:hover {
                    background: rgba(45, 159, 247, 0.06);
                }

                .titles {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                    width: 50%; // Don't know why this works, but it does.

                    .task {
                        color: $steel;
                    }

                    > h3, p {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .description {
                        font-style: italic;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    p {
                        &:first-of-type {
                            font-weight: bold;
                            color: #3a455b;
                        }
                    }
                }

                button {
                    text-transform: uppercase;
                    background-color: transparent;
                    color: $dark-sky-blue;

                    &:hover {
                        background-color: transparent !important;
                    }
                }

                p,
                h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                }
            }
        }
    }
}

.viewSetting {
    padding-left: 12px;

    label {
        * {
            font-size: 12px !important;
            white-space: nowrap;
        }
    }

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 8px !important;

    p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        font-weight: bold;
    }
}



.gridView {
    width: 50vw;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
    box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);

    .topBar {
        height: 40px;
        background-color: $dark-grey-blue;
        display: flex;
        align-items: center;
        color: white;
        padding: 8px 16px;
        flex-shrink: 0;
        -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);

        h1 {
            margin: 0;
            padding: 0;
            font-size: 16px;
            font-weight: 500;
            flex: 1;
        }
    }

    .gridEntryContainer {
        display: grid;
        //grid-template-columns: 50%, 50%;
        grid-template-columns: repeat(auto-fill, minmax(50%, 50%));
        overflow-y: auto;

        .highlightContainer {
            display: flex;
            align-items: center;
            padding: 30px 12px;
            cursor: pointer;
            border-radius: 10px;

            &:hover {
                background: rgba(45, 159, 247, 0.06);
            }

            .titles {
                display: flex;
                flex-direction: column;
                flex: 1;

                .task {
                    color: $steel;
                }

                .description {
                    font-style: italic;
                }

                p {
                    &:first-of-type {
                        font-weight: bold;
                        color: #3a455b;
                    }
                }
            }

            button {
                text-transform: uppercase;
                background-color: transparent;
                color: $dark-sky-blue;

                &:hover {
                    background-color: transparent !important;
                }
            }

            p,
            h3 {
                margin: 0;
                padding: 0;
                font-size: 12px;
            }
        }
    }



    .previousEntrySettings {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        background-color: $pale-grey-three;
        padding: 4px 4px;
        -webkit-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        -moz-box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);
        box-shadow: inset -7px 0 9px -7px rgba(0, 0, 0, 0.4);

        label {
            margin-left: 0;
            margin-right: 4px;

            >span {
                display: flex;
                align-items: center;
                font-size: 12px;

                svg {
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }

    img {
        width: 80px;
        height: 80px;
        margin-top: 12px;
        margin-left: 12px;
    }


    ul {
        list-style: none;
        padding: 10px 0;
        margin: 0;
        overflow: auto;
        flex: 1;

        li {
            padding: 0px 12px;

            .highlightContainer {
                display: flex;
                align-items: center;
                padding: 10px 12px;
                cursor: pointer;
                border-radius: 10px;

                &:hover {
                    background: rgba(45, 159, 247, 0.06);
                }

                .titles {
                    display: flex;
                    flex-direction: column;
                    flex: 1;

                    .task {
                        color: $steel;
                    }

                    .description {
                        font-style: italic;
                    }

                    p {
                        &:first-of-type {
                            font-weight: bold;
                            color: #3a455b;
                        }
                    }
                }

                button {
                    text-transform: uppercase;
                    background-color: transparent;
                    color: $dark-sky-blue;

                    &:hover {
                        background-color: transparent !important;
                    }
                }

                p,
                h3 {
                    margin: 0;
                    padding: 0;
                    font-size: 12px;
                }
            }
        }
    }

}
