.notification {
    display: flex;
    width: 410px;
    min-height: 84px;
    padding-bottom: 16px;
    margin-top:6px;
    border-radius: 8px;
    margin-left: 12px;
    margin-right: 12px;
    &.unseen {
        background: rgba(44, 159, 247, 0.08);
    }
    &.seen {
        background: #f9f9f9;
    }
    .left {
        margin-top: 20px;
        margin-left: 12px;
        margin-right: 12px;
        .iconContainer {
            width: 30px;
            height: 30px;
            border-radius: 4px;
            background-color: #d2e5f3;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 20px;
                height: 20px;
                fill: #6B7897;
            }
        }
    }
    .right {
        margin-top: 16px;
        margin-left: 16px;
        margin-right: 16px;
        display: flex;
        flex-direction: column;
        flex: 1;
        .top {
            margin-bottom: 6px;
            .main-header {
                display: flex;
                justify-content: space-between;
                color: #2c3547;
                .header {
                    font-size: 14px;
                    font-weight: 600;
                    margin-bottom: 4px;
                    margin-right: 10px;
                }
                .time {
                    font-size: 10px;
                    font-weight: 600;
                }
            }
            .sub-header {
                font-size: 10px;
            }
        }
        .bottom {
            .message {
                font-size: 12px;
                word-wrap: break-word;
            }
            .actions {
                display: flex;  
                justify-items: flex-start;
                flex-wrap: wrap;
                margin-top: 10px;
                .action {
                    padding-right: 28px;
                    font-size: 12px;
                    color: #2d9ff7;
                    cursor: pointer;
                    white-space: nowrap;
                }
            }
        }
    }
}
