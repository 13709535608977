@import '../colors.scss';

.addressListSection {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    > p {
        color: $steel;
        width: 100%;
    }
    .addDropdownContainer {
        display: flex;
        align-items: center;
        width: 100%;
        > div {
            flex: 1;
        }
        > button {
            color: $dark-sky-blue;
            border: none;
            background: none;
            font-weight: 600;
            margin-top: 0px;
            font-size: 12px;
            font-family: 'Open Sans', sans-serif !important;
            cursor: pointer;
            display: flex;
            align-items: center;
            padding: 0;
            margin-left: 16px;
            &:hover {
                opacity: 0.6;
            }
        }
    }
    .toolbar {
        display: flex;
        align-items: center;
        width: 100%;
        button {
            margin-right: 12px;
        }
    }
    > button,
    .addButton {
        color: $dark-sky-blue;
        border: none;
        background: none;
        font-weight: 600;
        margin-top: 0px;
        font-size: 12px;
        font-family: 'Open Sans', sans-serif !important;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0;
        > svg {
            color: $dark-sky-blue;
            height: 16px;
            width: 16px;
            margin-left: 4px;
        }
        &:hover {
            opacity: 0.6;
        }
    }
    .addButton {
        display: flex;
        align-items: center;
        margin-top: 6px;
        line-height: 1;
        > svg {
            color: $orangey-yellow !important;
            margin-left: 4px !important;
            height: 12px !important;
            width: 12px !important;
        }
    }
    .header {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 100%;
        > button {
            color: $dark-sky-blue;
            border: none;
            background: none;
            font-weight: bold;
            margin-left: 8px;
            font-family: 'Open Sans', sans-serif !important;
            font-size: 12px;
            padding: 0;
            cursor: pointer;
            display: flex;
            align-items: center;
            &:hover {
                opacity: 0.6;
            }
        }
        .left {
            display: flex;
            align-items: center;
            flex: 1;
            cursor: pointer;
            &:hover {
                opacity: 0.6;
            }
            > h2 {
                padding: 0;
                margin: 0;
                font-size: 14px;
            }
            > svg {
                margin-left: 6px;
                height: 20px;
                width: 20px;
                color: $dark;
            }
        }
    }
    .items {
        list-style: none;
        margin: 0;
        padding: 0;
        &.oneRowLast {
            margin-bottom: -16px;
        }
        width: 100%;
        flex: 1;
        box-sizing: border-box;
        // overflow: hidden;
        .itemContainer {
            display: flex;
            border-bottom: 1px solid #eee;
            position: relative;
            width: 100%;
            &.oneRow {
                align-items: center;
            }
            // &.editable {
            //     cursor: pointer;
            // }
            &:last-child {
                border-bottom: none;
            }
            .rightSide {
                padding: 16px 0;
                &.oneRow {
                    padding: 0;
                }
                display: flex;
                justify-content: flex-end;
                align-items: center;
                height: fit-content;
                margin-right: -8px;
                margin-left: 16px;
                > div {
                    display: flex;
                    align-items: center;
                    > span {
                        padding: 0;
                        margin-left: 6px;
                        svg {
                            height: 18px;
                            width: 18px;
                        }
                    }
                    :global {
                        .context-menu {
                            min-width: 0;
                            padding: 0;
                            margin-left: 6px;
                            &:hover {
                                background: none;
                            }
                        }
                        #menu-list-grow {
                            li {
                                font-size: 12px;
                                font-weight: 500;
                                svg {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 8px;
                                }
                                &.delete {
                                    svg {
                                        color: $faded-red;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            > li {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 16px 0;
                overflow: hidden;
                > button,
                .addButton {
                    width: fit-content;
                    color: $dark-sky-blue;
                    border: none;
                    background: none;
                    font-family: 'Open Sans', sans-serif !important;
                    font-weight: bold;
                    font-size: 12px;
                    padding: 0;
                    margin: 0;
                    margin-top: 10px;
                    cursor: pointer;
                    &:hover {
                        opacity: 0.6;
                    }
                }
                .addButton {
                    margin-top: 0;
                }
                .subheader {
                    > div {
                        display: flex;
                        align-items: center;
                        margin: 12px 0 0 0;
                        width: fit-content;
                        max-width: 100%;
                        > h3 {
                            padding: 0;
                            font-size: 13px;
                            line-height: 1;
                        }
                        .tooltip {
                            height: 14px;
                            width: 14px;
                            margin-left: 6px;
                            color: #aaa;
                        }
                    }
                    > p {
                        margin: 0;
                        margin-top: 8px;
                        font-size: 12px;
                    }
                }
                .fieldRow {
                    display: flex;
                    align-items: flex-start;
                    overflow: hidden;
                    width: 100%;
                    margin-top: 8px;
                    &.center {
                        align-items: center;
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                    .switchValueRow {
                        display: flex;
                        align-items: center;
                        h3 {
                            margin-left: 8px;
                        }
                    }
                    .title {
                        display: flex;
                        align-items: center;
                        width: 120px;
                        margin-right: 16px;
                        overflow: hidden;
                        flex-shrink: 0;
                        p {
                            padding: 0;
                            margin: 0;
                            font-size: 12px;
                            max-width: 100%;
                        }
                        .tooltip {
                            height: 14px;
                            width: 14px;
                            margin-left: 6px;
                            color: #aaa;
                        }
                    }
                    svg {
                        width: 20px;
                        height: 20px;
                        color: $greenish-cyan;
                        &.false {
                            color: #aaa;
                        }
                    }
                    h3 {
                        flex: 1;
                        padding: 0;
                        margin: 0;
                        font-size: 12px;
                        // white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        &.multiline {
                            white-space: pre-wrap;
                        }
                    }
                }
            }
        }
    }
}
