@import '../colors.scss';

.notificationBar {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1 0;
    width: 100%;
    padding: 0px;
    svg {
        height: 20px;
        width: 20px;
        margin-right: 6px;
        margin-left: -2px;
    }
    .notification {
        padding: 6px 14px;
        font-size: 12px;
        color: $orangey-yellow;
        display: flex;
        align-items: center;
        background-color: rgba(247, 188, 89, 0.1);
        font-weight: bold;
        height: 20px;
        border-radius: 5px;
        .button {
            cursor: pointer;
            margin-left: 28px;
            color: #2D9FF7;
            white-space: nowrap;
        }
        &.serious {						
            color: $warm-pink;
            background-color: rgba(247, 84, 143, 0.1);
            > svg {
                color: $warm-pink;
                fill: $warm-pink;
            }
        }
        &.warning {
            background: #fce8c7;
            color: #c38115;
            > svg {
                color: $orangey-yellow;
                fill: $orangey-yellow;
            }
        }
        &.sliderHeader {
            background: rgba(255, 255, 255, 0.1);
            color: #F7BC59;
            > svg {
                color: #F7BC59;
                fill: #F7BC59;
            }
            padding: 8px 14px;
            margin-right: 12px;
        }
    }
}