#quotePrintPreview {
    flex: 1;
    align-self: stretch;
    display: flex;
    overflow: hidden;
    .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        overflow: auto;
    }
    .right {
        width: 400px;
        height: 100%;
        background-color: white;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
            font-size: 24px;
        }
        .onboardingContent {
            margin: 0 -32px;
            width: calc(100% + 64px);
            > div {
                height: fit-content;
            }
        }
        border-left: 1px solid #dde3e8;
        .settings {
            flex: 1;
            padding: 32px;
            align-self: stretch;
            box-sizing: border-box;
            .fields {
                display: flex;
                flex-direction: column;
                margin-top: 8px;
            }
            h2 {
                margin: 0;
                padding: 0;
                font-size: 16px;
            }
        }

        .disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
