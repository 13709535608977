.row {
    :global(.inner) {
        font-weight: 600;
        text-indent: 16px;
    }
    .negative {
        :global(.inner) {
            color: #f7548f;
        }
    }
}