.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 64px;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100000;
}

.x {
    position: fixed;
    top: 30px;
    right: 30px;
    width: 35px;
    height: 35px;
    fill: #fff;
    cursor: pointer;
}

.image {
    max-width: 100%;
    max-height: 100%;
    display: block;

    width: unset !important;
    height: unset !important;
}

.wrapper {
    background: transparent !important;
}
