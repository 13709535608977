.root {
    border-radius: 5px;
    background: #FFFFFF;

    &.open {
        height: 100%;
    }
}

.handle {
    height: 20px;
    cursor: move;
}

.title {
    padding: 20px;
    padding-top: 0;
    display: flex;
    font-weight: 600;
    font-size: 16px;
    align-items: center;
    justify-content: space-between;

    .left {
        display: flex;
        flex-direction: column;
        > div {
            display: flex;
            align-items: center;
            svg {
                margin-left: 4px;
                margin-top: 1px;
            }
        }

        .subtitle {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            color: #3A455B;
        }
    }

    .right {
        :global(.insight-drop-down) {
            button {
                background-color: #e3f4ff !important;
			    cursor: pointer;
            }
        }
    }

    .collapeable {
        cursor: pointer;
    }
}