@import '../../theme';

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes animateOut {
    0% {
        transform: translateY(0) scale(1);
    }
    30% {
        transform: translateY(-50px) scale(1.05);
    }
    100% {
        transform: translateY(500px) scale(0.5);
    }
}

@keyframes animateIn {
    0% {
        transform: translateY(500px) scale(0.5);
    }
    100% {
        transform: translateY(0) scale(1);
    }
}

@keyframes hide {
    0% {
        max-height: 1000px;
        padding: 20px 20px 0 20px;
    }
    100% {
        max-height: 0;
        padding: 0 20px 0 20px;
    }
}

@keyframes hidePartially {
    0% {
        max-height: 1000px;
        margin-top: 16px;
    }
    100% {
        max-height: 0;
        margin-top: 0;
        overflow: hidden;
    }
}

@keyframes show {
    0% {
        max-height: 0;
        padding: 0 20px 0 20px;
    }
    100% {
        max-height: 1000px;
        padding: 20px 20px 0 20px;
    }
}

.onboardingSection {
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    padding-bottom: 0px;
    overflow: hidden;
    &.hide {
        animation: hide 300ms ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    &.hidePartially {
        .content {
            .cards {
                animation: hidePartially 300ms ease-in-out;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
            }
        }
    }
    &.show {
        animation: show 300ms ease-in-out;
        animation-fill-mode: forwards;
        animation-iteration-count: 1;
    }
    .content {
        width: 100%;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        padding: 32px;
        box-sizing: border-box;
        background-color: white;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
        overflow: hidden;
        position: relative;
        > img {
            z-index: 0;
            position: absolute;
            height: 200px;
            top: 10px;
            right: 32px;
            max-width: 30%;
            &.animateIn {
                animation: fadeIn 500ms ease-in-out;
                animation-fill-mode: forwards;
                animation-iteration-count: 1;
            }
        }
        .top {
            display: flex;
            align-items: center;
            flex-shrink: 0;
            z-index: 1;
            .titles {
                display: flex;
                flex-direction: column;
                flex: 1;
                h1,
                p {
                    margin: 0;
                    padding: 0;
                }
                h1 {
                    @include themedModule() {
                        color: t('title');
                    }
                }
                p {
                    font-weight: bold;
                    margin-top: 2px;
                    @include themedModule() {
                        color: t('subtitle');
                    }
                }
            }
            .right {
                display: flex;
                align-items: center;
                max-width: 50%;
                position: relative;
                &.animateOut {
                    animation: fadeOut 500ms ease-in-out;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                }
                &.animateIn {
                    animation: fadeIn 500ms ease-in-out;
                    animation-fill-mode: forwards;
                    animation-iteration-count: 1;
                }
                .progress {
                    width: 200px;
                    max-width: 100%;
                    margin-left: 16px;
                    > span {
                        width: 100%;
                        height: 8px;
                    }
                    p {
                        margin: 0;
                        margin-top: 6px;
                        padding: 0;
                        font-size: 10px;
                        font-weight: bold;
                        color: $steel;
                        text-align: right;
                        justify-self: flex-end;
                    }
                }
                > .cancelButton {
                    background-color: transparent;
                    min-width: 0;
                    padding: 8px;
                    margin-left: 32px;
                    &:hover {
                        background-color: #eee;
                    }
                }
            }
        }
        .cards {
            display: flex;
            align-items: stretch;
            justify-content: center;
            flex-wrap: wrap;
            flex: 1;
            margin-top: 16px;
            .cardContainer {
                position: relative;
                flex: 1;
                min-width: 200px;
                max-width: 800px;
                margin: 0 8px;
                margin-top: 16px;
                &.sliderMode {
                    max-width: 50%;
                }
                &.finishCardContainer {
                    height: 278px;
                }
                &.animateOut {
                    animation: animateOut 500ms ease-in-out;
                    animation-delay: calc(100ms * var(--animation-stutter));
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
                &.animateIn {
                    transform: translateY(500px) scale(0.5);
                    animation: animateIn 500ms ease-in-out;
                    animation-delay: calc(100ms * var(--animation-stutter));
                    animation-iteration-count: 1;
                    animation-fill-mode: forwards;
                }
                .confetti {
                    position: absolute;
                    left: -250px;
                    right: -250px;
                    top: -250px;
                    bottom: -250px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
            .card {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: white;
                border-radius: 10px;
                padding: 24px;
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
                transition: transform 300ms ease-in-out;
                &.completed {
                    pointer-events: none;
                    background-color: #f1fdfa;
                }
                &:hover {
                    transform: scale(1.1);
                    z-index: 99;
                    .colorLine {
                        max-height: 600px;
                    }
                    > svg {
                        g {
                            path {
                                &:first-of-type {
                                    fill: white !important;
                                }
                            }
                        }
                    }
                    .texts {
                        h2,
                        p {
                            color: white;
                        }
                    }
                }
                button {
                    white-space: nowrap;
                    &.completed {
                        pointer-events: none;
                        background-color: rgba(66, 182, 119, 0.1);
                        color: #42b677;
                    }
                }
                .colorLine {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 1000px;
                    max-height: 8px;
                    z-index: 0;
                    transition: max-height 300ms ease-in-out;
                }
                .completedIndicator {
                    position: absolute;
                    right: 12px;
                    top: 12px;
                    background-color: $greenish-cyan;
                    border-radius: 50%;
                    height: 30px;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &.animated {
                        animation: roll 500ms ease-in-out;
                    }
                    svg {
                        height: 20px;
                        width: 20px;
                        fill: white;
                    }
                }
                > svg {
                    height: 80px;
                    width: 80px;
                    margin-top: 16px;
                    z-index: 1;
                    transition: fill 300ms ease-in-out;
                    g {
                        path {
                            transition: fill 300ms ease-in-out;
                        }
                    }
                }
                .texts {
                    margin: 16px 0;
                    z-index: 1;
                    flex: 1;
                    h2,
                    p {
                        margin: 0;
                        padding: 0;
                        text-align: center;
                        transition: color 300ms ease-in-out;
                    }
                    h2 {
                        font-size: 14px;
                    }
                    p {
                        font-size: 12px;
                        margin-top: 2px;
                        overflow: hidden;
                    }
                }
            }
            .finishCard {
                height: 100%;
                width: 100%;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                background-color: white;
                border-radius: 10px;
                padding: 24px;
                position: relative;
                overflow: hidden;
                box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.08);
                transition: transform 300ms ease-in-out;
                &.filled {
                    background-color: #9fcded;
                    h2 {
                        color: white !important;
                    }
                    > svg {
                        transform: rotateZ(-60deg);
                        right: 30px;
                        bottom: 0;
                    }
                }
                // &:hover {
                //     transform: scale(1.15);
                //     z-index: 99;
                //     .colorLine {
                //         max-height: 300px;
                //     }
                //     .texts {
                //         h2 {
                //             color: white !important;
                //         }
                //     }
                // }
                .colorLine {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    height: 300px;
                    max-height: 8px;
                    z-index: 0;
                    transition: max-height 300ms ease-in-out;
                }
                > svg {
                    position: absolute;
                    right: 16px;
                    bottom: 16px;
                    z-index: 1;
                    width: 200px;
                    height: 200px;
                    z-index: 0;
                }
                .texts {
                    margin: 16px 0;
                    z-index: 1;
                    h2,
                    p {
                        margin: 0;
                        padding: 0;
                        text-align: left;
                        transition: color 300ms ease-in-out;
                    }
                    h2 {
                        font-size: 20px;
                    }
                    a {
                        display: block;
                        margin-top: 4px;
                        font-weight: bold;
                        display: flex;
                        align-items: center;
                        font-size: 16px;
                        svg {
                            color: $dark-sky-blue;
                        }
                    }
                }
            }
        }
    }
}
