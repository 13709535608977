@import '../colors.scss';
@import '../theme';

.headerPreviewList {
    .actionButton {
        color: white;

        &:hover {
            background-color: rgb(31, 111, 172);
        }

        svg {
            color: white !important;
            fill: white !important;
        }
    }
}

.divider {
    position: absolute;
    bottom: 0;
    right: 20px;
    left: 20px;
    height: 1px;
    background-color: #eee;
}

.onboardingIndicatorContainer {
    display: flex;
    align-items: center;
    position: relative;
    .confetti {
        position: absolute;
        left: -150px;
        right: -150px;
        top: -250px;
        bottom: -150px;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;
    }
}

.cancellationIndicator {
    font-size: 11px;
    color: $orangey-yellow;
    margin-right: 12px;
    display: flex;
    align-items: center;
    background-color: rgba(247, 188, 89, 0.1);
    font-weight: bold;
    padding: 6px 14px;
    border-radius: 5px;
    cursor: pointer;
    > svg {
        color: $orangey-yellow;
        height: 20px;
        width: 20px;
        margin-right: 6px;
        margin-left: -2px;
    }
    &.serious {
        color: $warm-pink;
        background-color: rgba(247, 84, 143, 0.1);
        > svg {
            color: $warm-pink;
        }
    }
}

.trialIndicator {
    font-size: 11px;
    color: #2d9ff7;
    margin-right: 12px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    button {
        padding: 4px 10px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EAF5FE;
        font-size: 11px;
        font-weight: bold;
        transition: background-color 300ms ease-in-out;
        min-width: 0;
        color: $dark-sky-blue;
        white-space: nowrap;
        &:hover {
            cursor: pointer;
            background-color: $dark-sky-blue;
            color: white;
        }
    }
}

.actionButton {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px !important;
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    position: relative;
    &:hover {
        background-color: #eee;
    }
    .upgradeIndicator {
        color: $orangey-yellow !important;
        margin-left: 2px !important;
        height: 12px !important;
        width: 12px !important;
    }
    .withInfoLabel {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        p {
            margin: 0;
            padding: 0;
            font-size: 9px;
            color: #6b7897;
            margin-bottom: -3px;
            margin-top: 2px;
            line-height: 1;
        }
    }
    svg:not(.upgradeIndicator) {
        height: 20px;
        width: 20px;
        @include themedModule() {
            color: t('title') !important;
            fill: t('title') !important;
        }
    }
    &.active {
        svg {
            color: $dark-sky-blue !important;
            fill: $dark-sky-blue !important;
        }
    }
}

.notificationBadge {
    span {
        min-width: 16px;
        height: 16px;
        font-size: 9px;
        margin-right: 2px;
        margin-top: 2px;
        color: #ffffff;
        background-color: #f7548f;
    }
}

.separator {
    height: 30px;
    width: 1px;
    background-color: $border;
    margin-right: 12px;
    margin-left: 4px;
}

.addNewMenu {
    margin: 0;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    color: $subtitle;
    border-radius: 5px;
    padding: 4px;
    &:hover {
        background-color: #eee;
    }
    &:after {
        margin-left: 8px !important;
        margin-bottom: 2px;
        border-color: $subtitle;
    }
}
