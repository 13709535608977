.indicator {
    padding: 8px 12px;
    font-size: 12px;
    font-weight: bold;
    border-radius: 5px !important;
    display: flex;
    width: fit-content !important;
    line-height: 1.2;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    > svg {
        margin-right: 6px !important;
        height: 20px;
        width: 20px;
    }
}
