@import '../colors.scss';
@import '../_theme.scss';

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

// Disable menu when dragging
body.react-draggable-transparent-selection {
    #main-navigation>.navi-container {
        pointer-events: none;
    }
}

#main-navigation > .navi-container {
    font-family: 'Open Sans', sans-serif;

    $header_height: 55px;
    $normal_width: 230px;
    $hidden_width: 70px;

    position: relative;

    .header {
        height: $header_height;
        width: 100%;
        background: white;
        position: fixed;
        min-width: 850px;
        box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
        padding-left: $normal_width;
        box-sizing: border-box;
        transition: padding 0.5s;

        &.hidden {
            padding-left: $hidden_width;
        }

        .header-button-container {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;

            &.header-preview-list {
                background: $deep-sky-blue;

                .tree-label-container {
                    color: white;
                }

                .menu-button {
                    svg {
                        color: white;
                    }
                }

                .tree-label-container {
                    .back-button {
                        &:hover {
                            background: $sky-blue;
                        }

                        svg {
                            color: white;
                        }
                    }
                }

                .sublabel {
                    a,
                    a:visited {
                        color: rgba(white, 0.6);
                    }
                }
            }

            .tree-label-container {
                display: flex;
                align-items: center;
                overflow: hidden;
                margin-right: 16px;
                @include themed() {
                    color: t('title');
                }
                .back-button {
                    border: none;
                    background: none;
                    padding: 4px 2px;
                    // margin: 0 8px 0 -8px;
                    margin-right: 8px;
                    cursor: pointer;
                    border-radius: 5px;
                    &:hover {
                        background: #eee;
                    }
                    svg {
                        color: $steel;
                        height: 18px;
                        width: 18px;
                        margin: 0 4px;
                    }
                }
                .line-separator {
                    height: 30px;
                    width: 1px;
                    border-radius: 2px;
                    background-color: #c3cbdb;
                    margin: 0 16px;
                }
                h1 {
                    font-size: 16px;
                    white-space: nowrap;
                    margin: 0;
                    margin-block-start: 0;
                    margin-block-end: 0;
                    padding: 0;
                    line-height: 1;
                    animation: fadeIn 300ms ease-in-out;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1.2;
                }
                a {
                    line-height: 1;
                    font-weight: bold;
                    display: block;
                }
                .sublabel {
                    margin-top: 1px;
                    animation: fadeIn 300ms ease-in-out;
                    color: $steel;
                    h1,
                    a {
                        font-size: 12px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        line-height: 1.2;
                    }
                }
                > div {
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    h1 {
                        font-size: 14px;
                    }
                }
            }

            // padding-right: 63px;

            .menu-button-reports {
                color: $title;
            }
            .menu-button-reports:visited {
                color: $title;
            }
            .devOptionsButton {
                margin-right: 8px;
            }
            > div {
                display: flex;
                align-items: center;

                &.devOptions {
                    min-width: 0;
                    flex-shrink: 10;
                    overflow: hidden;
                    align-items: center;

                    > div {
                        white-space: nowrap;
                    }
                }

                .menu-button {
                    display: flex;
                    align-items: center;

                    &.translate-button {
                        padding-left: 16px;

                        &::after {
                            border: none;
                        }
                    }
                }
            }
        }

        .sidebar {
            height: $header_height;
            display: flex;
            align-items: center;
            justify-content: space-between;
            box-sizing: border-box;
            padding: 8px 20px;
            overflow: hidden;
            > button {
                background: none;
                border: none;
                padding: 8px;
                margin-right: -8px;
                min-width: 0;
                cursor: pointer;
                border-radius: 10px;
                > svg {
                    color: $heerosBlue;
                }
                &:hover {
                    background-color: rgba(45, 159, 247, 0.06);
                }
            }
            &.hidden {
                padding: 8px;
                justify-content: center;
                > button {
                    margin-right: 0;
                }
            }
            @include themed() {
                background: t('sidebarTop');
                border-right: 1px solid t('sidebarBorder');
            }

            .logo,
            .logoWhite {
                height: 70%;
            }

            .logo {
                @include themed() {
                    display: t('logoDarkDisplay');
                }
            }

            .logoWhite {
                @include themed() {
                    display: t('logoWhiteDisplay');
                }
            }

            .hide-button {
                cursor: pointer;
                position: absolute;
                top: 10px;
                right: 10px;
                height: 35px;
                width: auto;
                display: block;
            }
        }

        #help-center-trigger {
            .appcues-widget-icon {
                display: none;
            }
        }

        .action-button {
            position: relative;
            cursor: pointer;
            margin-right: 10px;
            &.notes {
                margin-right: 8px;
            }
            &#headerNotificationFeed {
                margin-right: 5px;
            }
            .icon-text {
                svg {
                    width: 32px !important;
                    height: 32px !important;
                    display: block;
                }
            }
            img {
                width: 28px;
                height: 28px;
            }
            .marker {
                width: 9px;
                height: 9px;
                border-radius: 50%;
                color: green;

                position: absolute;
                left: 20px;
                top: 20px;
                margin: auto;

                &.green {
                    background: $greenish-cyan;
                }

                &.red {
                    background: $carnation-pink;
                }
            }

            > div.icon-text {
                display: flex;
                align-items: center;

                .info {
                    font-size: 10px;
                    color: #6b7897;
                    padding-left: 2px;
                    margin-right: 10px;
                }
            }

            img {
                display: inline-block;
            }

            &:hover {
                opacity: 0.8;
                cursor: pointer;
            }
        }
    }

    .sidebar {
        transition: width 0.5s;
        width: $normal_width;
        box-sizing: border-box;
        @include themed() {
            background: t('sidebarBG');
            border-right: 1px solid t('sidebarBorder');
        }
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        padding-top: 8px;

        &.sidebar-old {
            .menu-item {
                color: white;

                height: 44px;

                svg {
                    fill: #fff;

                    g {
                        fill: #fff;
                    }
                }

                &:hover,
                &.selected {
                    color: white;
                    opacity: 0.8;

                    svg {
                        fill: #fff;

                        g {
                            fill: #fff;
                        }
                    }
                }
            }

            .sub-item {
                background: #2c3547;
                border-left: 5px solid #2c3547;
                padding-left: 21px;
                text-align: left;
                font-size: 12px;
                display: block;
                .submenudot {
                    display: none;
                }

                &.selected {
                    border-left: 5px solid #2d9ff7;
                }
            }
        }

        &.hidden {
            width: $hidden_width;

            .main-item {
                div.name,
                span.count {
                    display: none;
                }
                justify-content: center;
                padding: 0;

                .icon-container {
                    padding: 0;
                }
            }

            .sub-item {
                display: none;
            }

            .expand {
                display: none;
            }

            .menu-item:after {
                display: none;
            }

            .logo {
                display: none;
            }

            #chatLauncher .text-container {
                display: none;
            }
        }

        overflow-y: auto;

        #chatLauncher {
            display: flex;
            align-items: center;
            cursor: pointer;
            padding: 0 16px;
            height: 40px;
            margin: 8px 8px;
            margin-bottom: 16px;
            box-sizing: border-box;
            border-radius: 10px;
            overflow: hidden;
            .icon-container {
                svg {
                    @include themed() {
                        color: t('sidebarItem');
                    }
                }
            }
            .text-container {
                @include themed() {
                    color: t('sidebarItem');
                }
                margin-left: 12px;
                font-size: 13px;
            }
            &:hover {
                @include themed() {
                    background: t('darkHoverBG');
                    color: t('sidebarItemActive');
                }
                .iconContainer {
                    svg {
                        @include themed() {
                            color: t('sidebarItemActive');
                        }
                    }
                }
                .text-container {
                    @include themed() {
                        color: t('sidebarItemActive');
                    }
                }
            }
        }        
    }

    & > .sidebar {
        top: $header_height;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }

        > .sidebarInner {
            &::-webkit-scrollbar {
                width: 0px;
                background: transparent;
            }

            scrollbar-width: none;
            overflow-y: auto;
        }
        > button {
            font-family: 'Open Sans', sans-serif;
            border: none;
            background: none;
            padding: 0 16px;
            height: 40px;
            margin: 8px 8px;
            box-sizing: border-box;
            border-radius: 10px;
            display: flex;
            align-items: center;
            font-size: 12px;
            cursor: pointer;
            &:hover {
                @include themed() {
                    background: t('darkHoverBG');
                    color: t('sidebarItemActive');
                }
                svg {
                    @include themed() {
                        fill: t('sidebarItemActive');
                    }
                }
            }
            @include themed() {
                color: t('sidebarItem');
            }
            svg {
                transition: none;
                height: 20px;
                width: 20px;
                margin-right: 6px;
                @include themed() {
                    fill: t('sidebarItem');
                }
            }
        }
    }
    .beta {
        font-size: 9px;
        background-color: rgba(0,58,120,0.1);
        color: #003A78;
        font-weight: bold !important;
        border-radius: 5px;
        padding: 2px 6px;
        margin-right: -1px;
        text-transform: uppercase;
        margin-top: 1px;
    }
    .tablet-mode .beta {
        display: none;
    }
}

.menuitem-tooltip-hidden {
    display: none;
}

.appcues-widget-container {
    .appcues-widget-dropdown {
        padding: 20px 0;
        box-shadow: 0 1px 15px 0 rgba(69, 65, 78, 0.1);
        border-radius: 3px;
        transform: translate(0, -4px);

        .appcues-widget-header,
        .appc-header {
            padding: 10px 24px !important;
            font-size: 14px;
        }

        .appcues-widget-content {
            img {
                display: none;
            }
        }

        .appcues-widget-content,
        .appcues-widget-footer {
            overflow: hidden;
            font-size: 12px;

            li,
            .appc-item {
                line-height: 20px !important;

                &:hover {
                    background: #eef7fe;
                }

                padding: 10px 24px !important;

                a {
                    padding: 0 !important;

                    &:hover {
                        background: transparent;
                    }
                }
            }

            li {
                height: 40px;
            }
        }
    }
}

.AddonTooltipInner {
    user-select: none;
    min-width: 210px;

    h2 {
        font-size: 14px;
        font-weight: 600;
        margin: 10px 15px;
    }

    div.addonBuyLink {
        padding: 15px;
        text-align: right;
        font-size: 14px;
        font-weight: 600;
    }

    p {
        font-size: 12px;
        color: #ffffff;
        margin: 0 15px;
    }
}

.PipelineTooltipInner {
    padding: 10px;
    font-size: 12px;
    line-height: 1.33;
}

.showNewMenu {
    color: white !important;
}
