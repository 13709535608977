@import "../colors.scss";

#SubtitleDialog {
    &.quoteScheduledInvoiceDialog {
        width: 900px;
        max-width: unset;

        .mainListOuterWrapper {
            .mainListWrapper {
                .mainList {
                    height: fit-content !important;
                    overflow: auto;
                    max-height: 400px;
                }
            }
        }

        .warning-text {
            margin-bottom: 20px;
        }

        .TMRDialogTopButtons {
            .close {
                margin-left: 44px;
            }
        }

        .addNewInstallment {
            width: fit-content;
            display: flex;
            align-items: center;
            cursor: pointer;
            margin-top: 20px;
            
            span {
                font-size: 12px;
                color: $deep-sky-blue;
                margin-left: 5px;
                font-weight: bold;
            }
            svg {
                color: $deep-sky-blue;
                width: 16px;
                height: 16px;
            }
        }

        .addRowsSeparately {
            margin-top: 20px;
            display: flex;
            align-items: center;
            font-size: 14px;

            .infoIcon {
                margin-top: 1px;
                height: 18px;
                width: 18px;
                margin-left: 6px;
                color: #aaa;
            }

            .switch {
                margin-left: 10px;
            }
        }

        .deleteCell {
            svg {
                height: 20px;
                width: 20px;
            }
        }
    }
}