@import '../colors.scss';

#entryOrderView {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('./img/onboarding-bg-blurred.jpg');
    background-repeat: no-repeat;
    background-size: cover;
}

.content {
    background-color: white;
    width: 600px;
    max-width: 90vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    h1,
    p,
    h2 {
        margin: 0 !important;
        padding: 0 !important;
    }
    > img {
        height: 220px;
        margin: 0;
        margin-top: 64px;
        margin-bottom: 16px;
        align-self: center;
    }
    .header {
        display: flex;
        flex-direction: column;
        padding: 32px;
        box-sizing: border-box;
        h1 {
            font-weight: bold;
            font-size: 26px;
        }
        p {
            color: $steel;
            font-size: 16px;
            margin-top: 8px !important;
        }
    }
    .benefits {
        padding: 0 32px;
        flex: 1;
        h2 {
            font-size: 16px;
        }
        > p {
            color: $steel;
            font-size: 16px;
            margin-top: 32px !important;
        }
        .campaignCode {
            background-color: rgba(247, 84, 143, 0.2);
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 32px 50px;
            border: 1px solid #f7548f;
            font-weight: bold;
            font-size: 16px;
            letter-spacing: 4px;
            margin: 0 auto;
            width: fit-content;
        }
        ul {
            list-style: none;
            padding: 0;
            li {
                display: flex;
                align-items: center;
                > svg {
                    color: $greenish-cyan;
                    height: 30px;
                    width: 30px;
                    margin-right: 12px;
                    &.red {
                        color: #f7548f;
                    }
                }
                &.more {
                    margin-top: 12px;
                    font-weight: bold;
                }
            }
        }
    }
    .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 32px;
        > button {
            // margin-left: 8px;
            height: 40px;
            // &:first-of-type {
                // border-color: #f7548f;
                // color: #f7548f;
                // &:hover {
                    // background: rgba(247, 84, 143, 0.1);
                // }
            // }
        }
    }
}
