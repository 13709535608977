@import './colors.scss';

$themes: (
    taimer: (
        sidebarTop: $dark,
        sidebarBG: #3a455b,
        sidebarItemActiveBG: $dark,
        sidebarItemActive: white,
        sidebarItem: #b2b9c9,
        sidebarBorder: transparent,
        darkHoverBG: $dark,
        tabSelectedIndicator: $deep-sky-blue,
        title: $title,
        logoWhiteDisplay: none,
        lightBG: '#f9f9f9',
        sliderTop: $dark-grey-blue,
        subtitle: $subtitle,
    ),
    heeros: (
        sidebarTop: white,
        sidebarBG: white,
        sidebarItem: $heerosBlue,
        sidebarItemActive: $heerosBlue,
        sidebarItemActiveBG: #F0F3F7,
        sidebarBorder: #c5c5c5,
        darkHoverBG: #F0F3F7,
        tabSelectedIndicator: $heerosBlue,
        title: $heerosBlue,
        logoWhiteDisplay: none,
        lightBG: #F0F3F7,
        sliderTopBG: $dark-grey-blue,
        sliderTop: white,
        subtitle: #6a79a8,
    ),
    teal: (
        sidebarItemActive: #00b8e4,
        sidebarItemActiveBG: rgba(0, 184, 228, 0.06),
        darkHoverBG: rgba(0, 184, 228, 0.06),
        tabSelectedIndicator: #00b8e4,
        sliderTopBG: #00b8e4,
    ),
    pink: (
        sidebarItemActive: #ec008a,
        sidebarItemActiveBG: rgba(236, 0, 138, 0.08),
        darkHoverBG: rgba(236, 0, 138, 0.08),
        tabSelectedIndicator: #ec008a,
        sliderTopBG: #ec008a,
    ),
    green: (
        sidebarItemActive: #50E3C2,
        sidebarItemActiveBG: rgba(80, 227, 194, 0.08),
        darkHoverBG: rgba(80, 227, 194, 0.08),
        tabSelectedIndicator: #50E3C2,
        sliderTopBG: #50E3C2,
    ),
    yellow: (
        sidebarItemActive: #FCD638,
        sidebarItemActiveBG: rgba(252, 214, 56, 0.08),
        darkHoverBG: rgba(252, 214, 56, 0.08),
        tabSelectedIndicator: #FCD638,
        sliderTopBG: #FCD638,
    ),
    purple: (
        sidebarItemActive: #6f42c1,
        sidebarItemActiveBG: rgba(111, 66, 193, 0.08),
        darkHoverBG: rgba(111, 66, 193, 0.08),
        tabSelectedIndicator: #6f42c1,
        sliderTopBG: #6f42c1,
    ),
);

@mixin themedModule() {
    $defaultTheme: map-get($themes, 'heeros');
    @each $theme, $themeMap in $themes {
        :global(.theme--#{"" + $theme}) & {
            $theme-map: () !global;
            @each $key, $submap in $defaultTheme {
                $fallback: map-get(map-get($themes, 'heeros'), '#{$key}');
                $value: map-get-with-fallback(map-get($themes, $theme), '#{$key}', $fallback);
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@mixin themed() {
    $defaultTheme: map-get($themes, 'heeros');
    @each $theme, $map in $themes {
        .theme--#{"" + $theme} & {
            $theme-map: () !global;
            @each $key, $submap in $defaultTheme {
                $fallback: map-get(map-get($themes, 'heeros'), '#{$key}');
                $value: map-get-with-fallback(map-get($themes, $theme), '#{$key}', $fallback);
                $theme-map: map-merge(
                    $theme-map,
                    (
                        $key: $value,
                    )
                ) !global;
            }
            @content;
            $theme-map: null !global;
        }
    }
}

@function t($key) {
    @return map-get($theme-map, $key);
}

@function map-get-with-fallback($map, $key, $fallback) {
    @if map-has-key($map, $key) {
        @return map-get($map, $key);
    } @else {
        @return $fallback;
    }
}
