$results_min_height: 300px;
$default_radius: 6px;

// Forms a "band" in the middle of the screen,
// where the search elements will be shown.
.band {
    position: relative;
    width: 40vw;
    left: calc(50% - 20vw);
}

.shadow {
    box-shadow: 0px 0px 16px #aaa;
}

.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    // Looks pretty good but causes severe lag.
    // backdrop-filter: blur(3px);
    top: 0;
    left: 0;
    z-index: 100000998;

    &.open {
        display: block;
    }

    &.closed {
        display: none;
    }
}

.contentWrapper {
    @extend .band;
    // @extend .shadow;

    position: fixed;
    top: 35vh;
    max-height: 80vh;
    border-radius: $default_radius;
    overflow: clip;
}

.searchInputWrapper {
    display: flex;
    background: #fff;
    height: 64px;
    line-height: 36px;
    box-sizing: border-box;
    padding: 6px 24px;

    .icon {
        flex: 1 1 0px;

        svg {
            height: 100%;
            width: 100%;
        }
    }

    .searchInput {
        font-family: "Open Sans";
        flex: 20 1 0px;
        color: #000;
        line-height: 36px;
        font-size: 16px;
        outline: none;
        text-align: left;
        border: none;
        box-sizing: border-box;
        margin-left: 16px;

        &::placeholder {
            color: #666666;
        }
    }
}

// Jes, important.
.resizedIcon {
    svg {
        width: 20px !important;
    }
}

.results {
    background: #fff;
    min-height: $results_min_height;
    box-sizing: border-box;
    padding: 8px;
    overflow: clip;
    border-top: 2px solid #d1d1d1;

    .resultsInner {
        // I am not good with computer
        min-height: 300px;
        max-height: 50vh;
        height: 100%;
        overflow-y: scroll;
        scrollbar-width: thin;
    }
}

.typeIndicator {
    width: calc(100% - 16px);
    margin: 8px;
    padding: 4px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
    color: #5d5d5d;
    // margin: 4px 8px 4px 8px;
    // border-bottom: 1px solid #a6abaf;
}

.result {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 56px;
    font-size: 16px;
    cursor: pointer;
    box-sizing: border-box;

    &.highlighted {
        background: #e8ecf4;
        border-radius: 5px;

        .icon {
            svg {
                fill: #000;
            }
        }
    }

    .icon {
        display: flex;
        flex: 1 1 0px;
        justify-content: center;
        align-items: center;

        svg {
            // width: 14px;
            width: 100%;
            fill: #000;
        }
    }

    .data {
        flex: 9 1 0px;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
        height: 100%;
        font-size: 14px;
        font-weight: 400;
        color: #000;
        
        display: flex;
        flex-direction: column;
        justify-content: center;

        text-overflow: ellipsis;

        .top {
            font-weight: 400;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .bottom {
            font-size: 12px;
            color: #767676;
            font-weight: 300;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .chev {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 0px;

        svg {
            fill: #cecece;
        }
    }

}

.flexContainer {
    display: flex;
    width: 100%;
    height: $results_min_height;
    align-items: center;
    justify-content: center;

    .message {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .messageText {
            font-size: 16px;

            &.margin {
                margin-top: 16px;
            }
        }
    }
}
