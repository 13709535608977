.root {
    // width: 200px;
    // height: 100%;
    display: flex;

    > div {
        > button, > a {
            margin-left: 10px;
        }
    }

    margin-right: 5px;
}

.page, .page_nr {
    color: white;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0 10px;
}

:global(#react-test) { // Required for overriding base styles

    .moveButton {
        border: 1px solid white;
        color: white;
        text-transform: uppercase;

        &:hover {
            opacity: 0.8;
        }

        &.disabled {
            opacity: 0.4;
        }

        &:visited {
            color: white;
        }
    }
}

.close {
    color: white;
}
