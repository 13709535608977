@import '../colors.scss';

#upgradeVersionSlider {
    min-width: 500px;
    max-width: 90vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    h1,
    p,
    h2 {
        margin: 0;
        padding: 0;
    }
    .top {
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px;
        > button {
            background-color: transparent;
            min-width: unset;
            margin-right: -16px;
        }
    }
    .content {
        flex: 1;
        overflow: hidden;
        margin-top: -24px;
        > div {
            overflow: auto;
        }
    }
}
