@import "../colors.scss";

.row {
    height: 32px;
    box-sizing: border-box;
    padding-top: 6px;
    display: flex;
    flex-direction: column;
    
    .customer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 2 1 auto;
        color: $steel;
        font-size: 10px;
        font-weight: 900;
    }

    .project {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 6 1 auto;
        color: #1b1b1b;
        font-size: 13px;
    }
}
