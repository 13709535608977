@import '../colors.scss';

.styledTooltip {
    align-items: center;
    display: block;
    margin-top: 2px;
    margin-bottom: 2px;

    p {
        margin: 0;
        padding: 2px 4px;
    }
    button {
        background: none;
        border: none;
        padding: 2px 4px;
        color: $dark-sky-blue;
        font-family: 'Open Sans', sans-serif;
        font-size: 12px;
        cursor: pointer;
    }
}

