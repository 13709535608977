@import '../colors.scss';

.upgradeVersionView {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    h1,
    p,
    h2 {
        margin: 0 !important;
        padding: 0 !important;
    }
    > img {
        width: 320px;
        max-width: 100%;
        max-height: 50%;
        min-height: 180px;
        margin: 0;
        margin-bottom: -24px;
        align-self: center;
    }
    .header {
        display: flex;
        flex-direction: column;
        padding: 32px;
        box-sizing: border-box;
        h1 {
            text-transform: uppercase;
            font-weight: bold;
            font-size: 22px;
        }
        p {
            color: $steel;
            font-weight: bold;
        }
    }
    .benefits {
        padding: 0 32px;
        flex: 1;
        h2 {
            font-size: 16px;
        }
        ul {
            list-style: none;
            padding: 0;
            li {
                display: flex;
                align-items: center;
                > svg {
                    color: $greenish-cyan;
                    height: 30px;
                    width: 30px;
                    margin-right: 12px;
                }
                &.more {
                    margin-top: 12px;
                    font-weight: bold;
                }
            }
        }
    }
    .pricing {
        padding: 0 32px;
        margin-top: 16px;
        h2 {
            font-size: 16px;
        }
        .prices {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 20px;
            .price {
                height: 150px;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                flex: 1;
                flex-shrink: 0;
                background-color: transparent;
                border: 1px dashed #dde3e8;
                padding: 16px;
                border-radius: 10px;
                box-sizing: border-box;
                margin-right: 16px;
                cursor: pointer;
                position: relative;
                &.selected {
                    background-color: rgba(45, 159, 247, 0.06);
                    border: 1px solid $dark-sky-blue;
                }
                &:last-of-type {
                    margin-right: 0;
                }
                .selectedIndicator {
                    position: absolute;
                    top: 8px;
                    right: 8px;
                    border-radius: 50%;
                    border: 1px solid #dde3e8;
                    height: 16px;
                    width: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    > svg {
                        display: none;
                        height: 12px;
                        width: 12px;
                        color: white;
                    }
                    &.selected {
                        background-color: $dark-sky-blue;
                        > svg {
                            display: block;
                        }
                    }
                }
                h3,
                p {
                    margin: 0;
                    padding: 0;
                }
                h3 {
                    font-size: 18px;
                    line-height: 1;
                }
                p {
                    margin-top: 8px;
                    font-size: 10px;
                    font-weight: bold;
                    color: $steel;
                    &.priceLabel {
                        color: $steel;
                        font-weight: bold;
                        font-size: 11px;
                        strong {
                            color: $dark;
                            font-size: 20px;
                        }
                        margin: 8px 0 !important;
                    }
                    &.campaign {
                        font-size: 12px;
                        color: $carnation-pink;
                        margin-top: 8px !important;
                    }
                }
            }
        }
    }
    .contactAdmin {
        box-sizing: border-box;
        height: 50px;
        margin: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        background-color: #eee;
        color: $steel;
        border-radius: 4px;
        flex-shrink: 0;
    }
    .cta {
        margin: 32px;
    }
}
