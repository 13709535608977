@import '../../colors.scss';

#GothiaSlider {
    width: 650px;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    box-sizing: border-box;
    overflow: auto;
}
